import { FC, useEffect, useRef, useState } from "react";
import { ExclamationIcon } from "../icons/exclamation-icon";
import { CrossIcon } from "../icons/cross-icon";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import L from "i18n-react";

interface AlertProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  text: string;
  title: string;
  duration?: number;
}

const Alert: FC<AlertProps> = ({
  isOpened,
  setIsOpened,
  text,
  title,
  duration = 3000,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isOpened) {
      timeoutId = setTimeout(() => {
        setIsOpened(false);
      }, duration);
    }
    return () => clearTimeout(timeoutId);
  }, [isOpened]);

  /*
  const makeTitle = () => {
    if (text === NetworkDisconnectMsg || text === APISomethingWrongMsg) {
      return "Error";
    } else {
      if (text.substring(0, 5) === "error") {
        return "Error";
      } else {
        return "Information";
      }
    }
  };
  */

  const makeText = () => {
    return text === "Unauthenticated" ? "Unauthenticated!" : L.translate(text);
    /*
    if (text.substring(0, 5) === "error") {
      return text.slice(6);
    } else {
      return text;
    }
    */
  };

  return (
    <div
      ref={containerRef}
      className={`fixed top-2 right-2 sm:top-4 sm:right-4 rounded-[16px] flex flex-row items-center justify-center shadow-card ${
        title == "Alert.error"
          ? "bg-error"
          : title == "Alert.sucess"
          ? "bg-success"
          : "bg-active"
      } z-[9999] w-[calc(100vw-40px)] max-w-[350px] scale-100 text-dark dark:text-light font-gilroy transition-all duration-300 ${
        isOpened ? "translate-x-0" : "translate-x-[400px]"
      }`}
    >
      <div className="flex items-center justify-center w-[87px] rounded-l-[16px]">
        <ExclamationIcon className={`w-[34px] h-[34px] text-white`} />
      </div>
      <div className="relative flex flex-col items-center justify-center gap-2 w-full px-4 py-4 bg-white h-full rounded-r-[16px]">
        <p
          className={`block font-gilroy text-[20px] font-medium text-primary w-full text-left pr-6`}
        >
          {text === "Unauthenticated"
            ? L.translate("Alert.error")
            : L.translate(title)}
        </p>
        <p className="font-gilroy text-[14px] font-medium text-primary w-full text-left pr-6">
          {/* break-all*/ makeText()}
        </p>
        <button
          className="absolute right-0 top-[12px] w-[32px] h-[32px]"
          onClick={() => setIsOpened(false)}
        >
          <CrossIcon className="w-[14px] h-[14px] text-primary" />
        </button>
      </div>
    </div>
  );
};

export default Alert;
