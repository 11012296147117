import L from "i18n-react";
import { ReceiptIcon } from "../icons/receipt-icon";
import { ListCheckIcon } from "../icons/list-check-icon";
import { DatabaseIcon } from "../icons/database-icon";

export const InvoiceBlockItem = ({
  icon,
  title,
  amount,
  backgroundColor,
}: {
  icon: React.ReactNode;
  title: string;
  amount: number;
  backgroundColor: string;
}) => {
  return (
    <div className="w-full flex flex-row items-center justify-between rounded-[8px] shadow-card px-4 py-6 group cursor-pointer">
      <div className="flex flex-row items-center justify-start gap-2">
        <div
          className={`w-12 h-12 flex items-center justify-center ${backgroundColor} rounded-[8px] group-hover:bg-opacity-60`}
        >
          {icon}
        </div>
        <div className="flex flex-col items-start justify-center">
          <p className="font-gilroy-semibold text-[14px] text-secondary">
            {title}
          </p>
          <p className="font-gilroy-bold text-[16px] text-primary">{amount}</p>
        </div>
      </div>
      <div
        className={`w-[30%] h-[2px] mt-12 ${backgroundColor} group-hover:bg-opacity-60`}
      />
    </div>
  );
};

const InvoiceBlocks = ({
  total_invoice,
  paid_invoice,
  pending_invoice,
}: {
  total_invoice: number;
  paid_invoice: number;
  pending_invoice: number;
}) => {
  return (
    <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-3">
      <InvoiceBlockItem
        icon={<ReceiptIcon className="text-white w-[20px] h-20px]" />}
        title={String(L.translate("CommonForm.total_invoice"))}
        amount={total_invoice}
        backgroundColor={"bg-active"}
      />
      <InvoiceBlockItem
        icon={<DatabaseIcon className="text-white w-[20px] h-[20px]" />}
        title={String(L.translate("CommonForm.pending_invoice"))}
        amount={pending_invoice}
        backgroundColor={"bg-error"}
      />
      <InvoiceBlockItem
        icon={<ListCheckIcon className="text-white w-[20px] h-[20px]" />}
        title={String(L.translate("CommonForm.paid_invoice"))}
        amount={paid_invoice}
        backgroundColor={"bg-success"}
      />
    </div>
  );
};

export default InvoiceBlocks;
