import React, { useState, useEffect } from "react";
import { SpinnerIcon } from "../icons/spinner-icon";
import UserPlaceholder from "../../assets/images/placeholder.png";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";

interface ImageProps {
  src: any;
  className: string;
  onClick?: any;
  isRoundedFull?: boolean;
}

const ImageWithLoading: React.FC<ImageProps> = ({
  src,
  className,
  onClick,
  isRoundedFull = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!isMounted) return;

    setIsLoading(true);
  }, [isMounted, src]);

  const onClickedImage = () => {
    onClick && onClick();
  };

  return (
    <div
      className={`relative min-w-[48px] aspect-square min-h-[48px] bg-gray-modern-100 flex items-center justify-center ${
        isRoundedFull ? "rounded-full" : "rounded-[8px]"
      }`}
    >
      <img
        src={src}
        className={className}
        onLoad={() => setTimeout(() => setIsLoading(false), 300)}
        onError={() => setTimeout(() => setIsLoading(false), 300)}
        onClick={onClickedImage}
      />
      {isLoading && (
        <div
          className={`w-full h-full absolute inset-0 flex items-center justify-center backdrop-blur-lg ${
            isRoundedFull ? "rounded-full" : "rounded-[8px]"
          }`}
        >
          <SpinnerIcon className="w-8 h-8 text-active animate-spin" />
        </div>
      )}
    </div>
  );
};

export default ImageWithLoading;
