import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WeekCalendar from "src/components/calendar/week-view";
import routes from "src/config/routes";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import L from "i18n-react";
import { Spin } from "antd";
import { Event } from "src/api/types";

const CalendarPage: FC = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const [data, setData] = useState<{ [key: number]: Event[] }>({});
  const [events, setEvents] = useState<Event[]>([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (!isMounted) return;
    if (startDate.length == 0 && endDate.length == 0) return;

    setTimeout(
      () => getList({ start_date: startDate, end_date: endDate }),
      300
    );
  }, [isMounted, startDate]);

  const { mutate: getList, isLoading } = useMutation(client.events.listByWeek, {
    onSuccess: (data) => {
      let events: Event[] = [];

      Object.keys(data.data).forEach((key) => {
        const eventsPerDay = data.data[Number(key)];
        if (eventsPerDay.length > 0) {
          events = [...events, ...eventsPerDay];
        }
      });

      const finalEvents = events.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      setEvents(finalEvents.sort((a, b) => b.id - a.id));
      setData(data.data);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_fetch_events")));
      }
      setIsAlertOpened(true);
    },
  });

  const onSelectEvent = (selEvent: Event) => {
    navigate(routes.eventPreview, { state: selEvent });
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] relative">
        {isLoading && (
          <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
        )}
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("Sidebar.calendar")}
        </div>
        <div className="mt-4">
          <WeekCalendar
            info={data}
            events={events}
            onSelectEvent={onSelectEvent}
            onSelectStartDate={(strDate: string) => setStartDate(strDate)}
            onSelectEndDate={(strDate: string) => setEndDate(strDate)}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
