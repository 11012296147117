import axios from "axios";
import {
  getAuthToken,
  removeAuthToken,
  removeAuthUserInfo,
} from "./token-util";
import { ConfigValue } from "../config";
import { Navigate } from "react-router-dom";
import routes from "src/config/routes";

// TODO: Due to windows timeout was set to 15000
const Axios = axios.create({
  baseURL: ConfigValue.PUBLIC_REST_API_ENDPOINT,
  timeout: 120000, //150000000,
  headers: {
    "Content-Type": "application/json",
  },
});
// Change request data/error here
Axios.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    config.headers.Authorization = token ? `Bearer ${token}` : undefined;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403)
    ) {
      removeAuthUserInfo();
      removeAuthToken();

      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await Axios.get<T>(url, { params });
    return response.data;
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await Axios.post<T>(url, data, options);
    return response.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await Axios.put<T>(url, data);
    return response.data;
  }

  static async delete<T>(url: string) {
    const response = await Axios.delete<T>(url);
    return response.data;
  }
}
