export const PaperPlaneTopIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_657)">
        <path
          d="M18.2635 7.27262L3.30429 0.235122C2.45929 -0.175711 1.46345 -0.0340445 0.764288 0.594289C0.0634546 1.22512 -0.179879 2.20512 0.143455 3.09012C0.157621 3.12596 3.81929 10.0035 3.81929 10.0035C3.81929 10.0035 0.225121 16.8793 0.212621 16.9143C-0.109879 17.8001 0.135955 18.7785 0.836788 19.4085C1.27095 19.7976 1.81929 19.9985 2.37095 19.9985C2.71179 19.9985 3.05345 19.9218 3.37179 19.766L18.2651 12.7343C19.336 12.2318 20.001 11.1851 20.0001 10.0026C20.0001 8.81929 19.3326 7.77262 18.2635 7.27262ZM1.69345 2.47262C1.59179 2.12679 1.80845 1.89762 1.87929 1.83262C1.95345 1.76679 2.22429 1.56262 2.57762 1.73596C2.58179 1.73762 17.556 8.78179 17.556 8.78179C17.7551 8.87512 17.921 9.00679 18.0485 9.16679H5.26262L1.69345 2.47262ZM17.5551 11.226L2.64845 18.2643C2.29429 18.4385 2.02429 18.2351 1.95012 18.1676C1.87845 18.1043 1.66179 17.8735 1.76429 17.5268L5.26595 10.8335H18.0535C17.926 10.996 17.7568 11.131 17.5551 11.226Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_657">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
