import React from "react";

export type MODAL_VIEWS =
  | "ACTION_SUCCESS"
  | "EMAIL_VERIFICATION"
  | "ADD_BASIC_ADMIN"
  | "EDIT_BASIC_ADMIN"
  | "DOCUMENTS"
  | "ADD_CLIENT"
  | "EDIT_CLIENT"
  | "COLLABORATOR_BID"
  | "COLLABORATOR_ADD"
  | "WEB_CASTING_CODE"
  | "EVENT_BID_DETAILS"
  | "COMP_DETAILS"
  | "SIGN_CONTRACT"
  | "EVENT_EDIT"
  | "EVENT_EDIT_DATES"
  | "REORDER_COLLABS"
  | "LEAVE_REVIEW"
  | "CHANGE_STATUS"
  | "CASTING_PHOTOS"
  | "ADS_STATUS"
  | "COLLAB_PICK_DATES"
  | "CONFIRM";

interface State {
  view: MODAL_VIEWS | undefined;
  data?: any;
  isOpen: boolean;
}
type Action =
  | { type: "open"; view: MODAL_VIEWS; payload?: any }
  | { type: "close" };

const initialState: State = {
  view: undefined,
  isOpen: false,
  data: null,
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case "open":
      return {
        ...state,
        view: action.view,
        data: action.payload,
        isOpen: true,
      };
    case "close":
      return {
        ...state,
        view: undefined,
        data: null,
        isOpen: false,
      };
    default:
      throw new Error("Unknown Modal Action!");
  }
}

const ModalStateContext = React.createContext<State>(initialState);
ModalStateContext.displayName = "ModalStateContext";
const ModalActionContext = React.createContext<
  React.Dispatch<Action> | undefined
>(undefined);
ModalActionContext.displayName = "ModalActionContext";

export default function ModalProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={dispatch}>
        {children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
}

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`);
  }
  return context;
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext);
  if (dispatch === undefined) {
    throw new Error(`useModalAction must be used within a ModalProvider`);
  }
  return {
    openModal(view: MODAL_VIEWS, payload?: unknown) {
      dispatch({ type: "open", view, payload });
    },
    closeModal() {
      dispatch({ type: "close" });
    },
  };
}
