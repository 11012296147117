import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import Button from "src/components/ui/button";
import {
  HairColors,
  HairColors_IT,
  LIST_PAGE_SIZE,
  LanguageLevels,
  LanguageLevels_IT,
  Positions,
  Positions_IT,
  ShoeSizes,
} from "src/lib/constants";
import { CollabDataType } from "src/lib/data-types";
import { useModalAction } from "src/components/modal-views/context";
import { SearchQueryOptions, User, UserType } from "src/api/types";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { DownloadIcon } from "src/components/icons/download-icon";
import axios from "axios";
import { ConfigValue } from "src/config";
import { API_ENDPOINTS } from "src/api/endpoints";
import { getAuthToken } from "src/api/token-util";
import L from "i18n-react";
import { MarkerIcon } from "src/components/icons/marker-icon";
import { MenuBurgerIcon } from "src/components/icons/menu-burger-icon";
import CollaboratorsListView from "src/components/collaborators/list";
import CollaboratorsMapView from "src/components/collaborators/map";
import { capitalizeFirstLetter } from "src/lib/utils";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import { PlusIcon } from "src/components/icons/plus-icon";
import useAuth from "src/hooks/use-auth";
import { AngleDownSmallIcon } from "src/components/icons/angle-down-small-icon";

const CollaboratorsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isMoreFilters, setMoreFilters] = useState(false);

  const { getCurrentUser } = useAuth();
  const userType = getCurrentUser().user_type ?? "";

  const { openModal } = useModalAction();
  const [isDownloading, setDownloading] = useState(false);
  const [isListMode, setListMode] = useState(true);
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);
  const [bRefreshList, setRefreshList] = useState(false);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onChangeViewMode = () => {
    setListMode(!isListMode);
  };

  const onShowMoreFilters = () => {
    setMoreFilters(!isMoreFilters);
  };

  const onAddCollab = () => {
    openModal("COLLABORATOR_ADD", {
      refreshCallback: () => setRefreshList(!bRefreshList),
    });
  };

  const onDownload = async () => {
    setDownloading(true);

    try {
      const response = await axios.post(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/${API_ENDPOINTS.COLLAB_USERS_DOWNLOAD}`,
        {
          responseType: "blob",
          shoe_sizes: ShoeSizes,
          hair_colors: language == "EN" ? HairColors : HairColors_IT,
          language_levels:
            language == "EN" ? LanguageLevels : LanguageLevels_IT,
          positions: language == "EN" ? Positions : Positions_IT,
        },
        {
          headers: {
            Authorization: `Bearer ${getAuthToken() ?? ""}`,
          },
        }
      );

      setDownloading(false);

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "collaborators.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setDownloading(false);

      console.error("Error downloading CSV file:", error);
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.error_download_csv")));
      setIsAlertOpened(true);
    }
  };

  return (
    <div className="w-full h-full bg-whole-gray relative">
      <div className="bg-white p-4 rounded-[8px] mb-6">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
          <div className="w-full sm:w-auto flex flex-row items-center justify-between gap-4">
            <div className="text-primary text-[24px] font-gilroy-semibold">
              {L.translate("Sidebar.collaborators")}
            </div>
            <Button
              variant="icon"
              className="bg-active p-2 rounded-[8px] hover:bg-main"
              onClick={onChangeViewMode}
            >
              {isListMode ? (
                <MarkerIcon className="text-white w-[20px] h-[20px]" />
              ) : (
                <MenuBurgerIcon className="text-white w-[20px] h-[20px]" />
              )}
            </Button>
          </div>
          <div className="w-full sm:w-auto flex flex-row items-center justify-between gap-2">
            <Button
              variant="icon"
              className="bg-purple p-2 rounded-[8px] hover:bg-red-400 text-white"
              onClick={onShowMoreFilters}
            >
              <AngleDownSmallIcon
                className={`${
                  isMoreFilters ? "rotate-180" : "rotate-0"
                } text-white w-[20px] h-[20px] duration-300`}
              />
            </Button>
            <Button
              variant="icon"
              className="bg-active p-2 rounded-[8px] hover:bg-main text-white"
              onClick={onAddCollab}
            >
              <PlusIcon className="text-white w-[20px] h-[20px]" />
            </Button>

            {userType == UserType.SUPER_ADMIN && (
              <Button
                variant="icon"
                className="bg-success p-2 rounded-[8px] hover:bg-success-100 text-white"
                onClick={onDownload}
                isLoading={isDownloading}
              >
                <DownloadIcon className="text-white w-[20px] h-[20px]" />
              </Button>
            )}
          </div>
        </div>
        {isListMode ? (
          <CollaboratorsListView
            bRefresh={bRefreshList}
            isMoreFilters={isMoreFilters}
          />
        ) : (
          <CollaboratorsMapView isMoreFilters={isMoreFilters} />
        )}
      </div>
    </div>
  );
};

export default CollaboratorsPage;
