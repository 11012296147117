export const CheckIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_691)">
        <path
          d="M0.257089 12.4503L4.07292 16.2661C5.14595 17.3388 6.88533 17.3388 7.95836 16.2661L19.7429 4.48158C20.0943 4.1177 20.0842 3.53787 19.7204 3.18645C19.3654 2.84361 18.8027 2.84361 18.4477 3.18645L6.66323 14.971C6.30554 15.3286 5.72574 15.3286 5.3681 14.971L1.55226 11.1552C1.18839 10.8037 0.608553 10.8138 0.257131 11.1777C-0.0857033 11.5326 -0.0857033 12.0953 0.257089 12.4503Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_691">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
