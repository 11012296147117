import { FC, useContext, useEffect, useState, useRef } from "react";
import CheckboxOptions from "src/components/ui/checkbox-options";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import Input from "src/components/ui/input";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactPhone } from "src/components/ui/phone-input";
import classNames from "classnames";
import TailwindDatePicker from "src/components/ui/date-picker";
import AddressInput from "src/components/ui/address-input";
import {
  HairColors,
  HairColors_IT,
  LanguageLevels,
  LanguageLevels_IT,
  Positions,
  Positions_IT,
  Provinces,
  ShoeSizes,
} from "src/lib/constants";
import SelectInput from "src/components/ui/select-input";
import PositionSelect from "src/components/ui/position-select";
import Button from "src/components/ui/button";
import { CheckboxIcon } from "src/components/icons/checkbox-icon";
import { SquareIcon } from "src/components/icons/square-icon";
import routes from "src/config/routes";
import { shakeElement } from "src/lib/shake-element";
import { useMutation } from "react-query";
import client from "../../api";
import { Event } from "src/api/types";
import { capitalizeFirstLetter, formatFileSize } from "src/lib/utils";
import L from "i18n-react";
import { Spin } from "antd";
import GlobalContext from "src/context/global-context";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import { useNavigate } from "react-router-dom";
import EventDetailsView from "src/components/events/details";
import Textarea from "src/components/ui/textarea";
import useAuth from "src/hooks/use-auth";
import InputNoSpaceWithoutLabel from "src/components/ui/input-nospace-without-label";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";

const CreateCastingPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const phoneRef = useRef<HTMLDivElement>(null);
  const shakeRef = useRef<HTMLDivElement>(null);
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const { getCurrentUser, setCurrentUser } = useAuth();

  const [events, setEvents] = useState<Event[]>([]);
  const [event, setEvent] = useState<Event | undefined>(undefined);

  const [phoneDivWidth, setPhoneDivWidth] = useState(0);

  const { mutate: getEvents, isLoading: isLoadingEvents } = useMutation(
    client.events.all,
    {
      onSuccess: (data) => {
        setEvents(data.results);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    getEvents("casting");
  }, [isMounted]);

  useEffect(() => {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);
  }, [phoneRef.current]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onBack = () => {
    navigate(-1);
  };

  return (
    <div className="w-full h-full bg-whole-gray relative">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>

        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("CommonForm.create_casting")}
        </div>
        <div className="mt-4">
          <DropdownWithoutLabel
            placeholder={String(L.translate("CommonForm.event_name"))}
            className="w-full sm:w-[70%]"
            data={events}
            onChange={(e: number) => {
              const selectedEvents = events.filter((item) => item.id == e);
              selectedEvents.length > 0 && setEvent(selectedEvents[0]);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCastingPage;
