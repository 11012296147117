export const MenuBurgerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_683)">
        <path
          d="M19.1667 9.16666H0.833333C0.373096 9.16666 0 9.53975 0 9.99999C0 10.4602 0.373096 10.8333 0.833333 10.8333H19.1667C19.6269 10.8333 20 10.4602 20 9.99999C20 9.53975 19.6269 9.16666 19.1667 9.16666Z"
          fill="currentColor"
        />
        <path
          d="M19.1667 3.33334H0.833333C0.373096 3.33334 0 3.70644 0 4.16668C0 4.62691 0.373096 5.00001 0.833333 5.00001H19.1667C19.6269 5.00001 20 4.62691 20 4.16668C20 3.70644 19.6269 3.33334 19.1667 3.33334Z"
          fill="currentColor"
        />
        <path
          d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_683">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
