import Datepicker, { PopoverDirectionType } from "react-tailwindcss-datepicker";
import classNames from "classnames";
import { forwardRef, Fragment, useEffect, useState, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import GlobalContext from "src/context/global-context";
import moment from "moment";

interface TailwindDatePickerProps {
  label?: string;
  placeholder: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  value?: string;
  onChange?: any;
  required?: boolean;
  displayFormat?: string;
  popoverDirection?: string;
}
const TailwindDatePicker = forwardRef<HTMLDivElement, TailwindDatePickerProps>(
  (
    {
      label,
      placeholder,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      onChange,
      value = "",
      required = false,
      displayFormat = "YYYY-MM-DD",
      popoverDirection = "down",
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState({
      startDate: value,
      endDate: value,
    });
    const { language } = useContext(GlobalContext);

    useEffect(() => {
      setSelectedValue({
        startDate: moment(value).format("YYYY-MM-DD"),
        endDate: moment(value).format("YYYY-MM-DD"),
      });
    }, [value]);

    const handleValueChange = (newValue: any) => {
      setSelectedValue(newValue);
      onChange(newValue.startDate);
    };

    return (
      <div className={className}>
        <label className="block text-[14px]">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <Datepicker
            i18n={language.toLowerCase()}
            placeholder={placeholder}
            useRange={false}
            displayFormat={displayFormat}
            asSingle={true}
            popoverDirection={popoverDirection as PopoverDirectionType}
            value={selectedValue}
            onChange={handleValueChange}
            inputClassName={classNames(
              "h-[44px] w-full placeholder-gray-modern-400 appearance-none border-none rounded-[8px] px-4 py-2 text-primary text-[14px] font-gilroy font-medium ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
              inputClassName
            )}
          />
        </label>
        {error && (
          <span role="alert" className="block pt-2 text-[12px] text-error">
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

TailwindDatePicker.displayName = "TailwindDatePicker";
export default TailwindDatePicker;
