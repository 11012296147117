export const AngleDownIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_663)">
        <path
          d="M10 5.69167C10.5476 5.69069 11.0899 5.79764 11.5961 6.00641C12.1023 6.21517 12.5623 6.52166 12.95 6.90834L19.7583 13.7167C19.9153 13.8736 20.0034 14.0864 20.0034 14.3083C20.0034 14.5303 19.9153 14.7431 19.7583 14.9C19.6014 15.0569 19.3886 15.1451 19.1667 15.1451C18.9448 15.1451 18.7319 15.0569 18.575 14.9L11.7667 8.09167C11.2979 7.6235 10.6625 7.36054 10 7.36054C9.33751 7.36054 8.70209 7.6235 8.23334 8.09167L1.42501 14.9C1.26809 15.0569 1.05526 15.1451 0.833342 15.1451C0.611423 15.1451 0.398596 15.0569 0.241676 14.9C0.0847569 14.7431 -0.0034008 14.5303 -0.0034008 14.3083C-0.0034008 14.0864 0.0847569 13.8736 0.241676 13.7167L7.05001 6.90834C7.43767 6.52166 7.89774 6.21517 8.40392 6.00641C8.9101 5.79764 9.45247 5.69069 10 5.69167Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_663">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(-1 0 0 -1 20 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
