import { FC, useContext, useEffect, useState } from "react";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import { EventStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import { EventDataType } from "src/lib/data-types";
import { Dropdown, Empty, MenuProps, Space, Spin, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  capitalizeFirstLetter,
  getContractStatusTextColor,
  getEventStatusColor,
  getEventStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import { SortIcon } from "src/components/icons/sort-icon";
import { SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { UserIcon } from "src/components/icons/user-icon";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import moment from "moment";

const ReportEventsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "name",
    direction: "desc",
    event_date: moment().format("yyyy-MM"),
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<EventDataType[]>([]);
  const { language } = useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_date,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 1124 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.reports.events, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          name: item.name,
          invoice_no: item.invoice_no ?? "-",
          description: item.description,
          account: item.account,
          quote_no: item.quote_no,
          invoice_descr: item.invoice_descr,
          city: item.city,
          contract: item.contract,
          contract_received: item.contract_received,
          number_of_collaborators: item.number_of_collaborators,
          numbers_per_day: item.numbers_per_day,
          contract_status: item.number_of_collaborators
            ? `${item.contract_received}/${item.number_of_collaborators}`
            : "",
          location: item.location ?? "",
          coordinate: item.coordinate ?? "",
          start_date: item.start_date ?? "",
          end_date: item.end_date ?? "",
          status: item.status,
          bid_status: item.bid_status,
          bid_comp: item.bid_comp ? `${item.bid_comp}` : "-",
          bid_reimburse: item.bid_reimburse ? `${item.bid_reimburse}` : "-",
          bid_contract_signed: item.bid_contract_signed,
          bid_contract_pdf: item.bid_contract_pdf ?? "",
          bid_notes: item.bid_notes ?? "",
          bid_event_date: item.bid_event_date,
          bid_payment_date: item.bid_payment_date,
          client_name: item.client.name,
          client: item.client,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }))
      );
    },
  });

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const onClickedEvent = (eventData: EventDataType) => {
    if (eventData.start_date.length == 0) {
      //Event should be planned
      navigate(routes.planJob, { state: eventData });
    } else {
      //View event details
      navigate(routes.eventPreview, { state: eventData });
    }
  };

  const columns: ColumnsType<EventDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event")),
          "name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "name",
      className: "w-auto",
      key: "name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.invoice_no")),
          "invoice_no",
          searchParams,
          setSearchParams
        ),
      dataIndex: "invoice_no",
      className: "w-[120px]",
      key: "invoice_no",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.client")}
        </p>
      ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.location")),
          "location",
          searchParams,
          setSearchParams
        ),
      dataIndex: "location",
      className: "w-auto",
      key: "location",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]  line-clamp-3">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_received")}
        </p>
      ),
      dataIndex: "contract_status",
      className: "w-auto",
      key: "contract_status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          {value.length > 0 && (
            <div className="flex flex-row gap-2 items-center">
              <UserIcon
                className={`${getContractStatusTextColor(
                  value
                )} w-[16px] h-[16px]`}
              />
              <p
                className={`${getContractStatusTextColor(
                  value
                )} font-gilroy text-[13px] sm:text-[14px]`}
              >
                {value}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.start_date")),
          "start_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "start_date",
      className: "w-[120px]",
      key: "start_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.end_date")),
          "end_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "end_date",
      className: "w-[120px]",
      key: "end_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: language == "EN" ? "w-[172px]" : "w-[200px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getEventStatusColor(value) }}
          >
            {L.translate(getEventStatusName(value))}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<EventDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col sm:flex-row gap-4 items-start justify-between">
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-col md:flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.invoice_no == "-"
                      ? selData.name
                      : `${selData.name} - ${selData.invoice_no}`}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.client")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.client_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.contract_received")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    {selData.contract_status.length > 0 && (
                      <div className="flex flex-row gap-2 items-center">
                        <UserIcon
                          className={`${getContractStatusTextColor(
                            selData.contract_status
                          )} w-[16px] h-[16px]`}
                        />
                        <p
                          className={`${getContractStatusTextColor(
                            selData.contract_status
                          )} font-gilroy text-[14px] font-medium`}
                        >
                          {selData.contract_status}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.start_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.start_date
                      ? makeFormattedDateString(selData.start_date)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.end_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.end_date
                      ? makeFormattedDateString(selData.end_date)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.status")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getEventStatusColor(selData.status),
                      }}
                    >
                      {L.translate(getEventStatusName(selData.status))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.location")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.location}
              </p>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-8 w-full relative">
      <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:items-center md:justify-start md:gap-4 mb-2">
        <div className="text-primary text-[16px] font-gilroy-semibold">
          {L.translate("CommonForm.event_statistics_month")}
        </div>
        <MonthPicker
          placeholder={String(L.translate("CommonForm.filter_by_month"))}
          value={searchParams.event_date ?? ""}
          onChange={(strDate?: string) =>
            setSearchParams({ ...searchParams, event_date: strDate ?? "" })
          }
        />
      </div>
      <Table
        locale={{
          emptyText: (
            <Empty
              description={
                <p className="font-gilroy-semibold text-secondary text-[14px]">
                  {L.translate("Base.no_data")}
                </p>
              }
            />
          ),
        }}
        showSorterTooltip={false}
        columns={isMobile ? mobileColumns : columns}
        dataSource={dataSource}
        loading={isLoading}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onClickedEvent(record),
          };
        }}
        pagination={{
          total,
          current: searchParams.page,
          onChange: onPageChange,
          position: ["bottomCenter"],
          defaultPageSize: LIST_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default ReportEventsPage;
