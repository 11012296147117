import L from "i18n-react";

export const ColumnsEventsBasicAdmin: { label: string; value: string }[] = [
  {
    value: "name",
    label: "CommonForm.event",
  },
  {
    value: "client_name",
    label: "CommonForm.client",
  },
  {
    value: "location",
    label: "CommonForm.location",
  },
  {
    value: "contract_status",
    label: "CommonForm.contract_received",
  },
  {
    value: "start_date",
    label: "CommonForm.start_date",
  },
  {
    value: "end_date",
    label: "CommonForm.end_date",
  },
  {
    value: "account",
    label: "CommonForm.account",
  },
  {
    value: "status",
    label: "CommonForm.status",
  },
];

export const ColumnsEventsAdmin: { label: string; value: string }[] = [
  {
    value: "name",
    label: "CommonForm.event",
  },
  {
    value: "contract",
    label: "CommonForm.cost",
  },
  {
    value: "invoice_no",
    label: "CommonForm.invoice_no",
  },
  {
    value: "client_name",
    label: "CommonForm.client",
  },
  {
    value: "location",
    label: "CommonForm.location",
  },
  {
    value: "contract_status",
    label: "CommonForm.contract_received",
  },
  {
    value: "start_date",
    label: "CommonForm.start_date",
  },
  {
    value: "end_date",
    label: "CommonForm.end_date",
  },
  {
    value: "account",
    label: "CommonForm.account",
  },
  {
    value: "status",
    label: "CommonForm.status",
  },
];
