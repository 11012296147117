import { Fragment, useContext, useEffect, useState } from "react";
import routes from "../config/routes";
import Button from "../components/ui/button";
import Hamburger from "../components/ui/hamburger";
import ThemeContext from "../lib/theme/theme-context";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/use-auth";
import { SettingsIcon } from "src/components/icons/settings-icon";
import LangSwitcher from "src/components/ui/switcher-lang";
import L from "i18n-react";

function AuthorizedMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex gap-[8px] sm:gap-[20px]">
      <Button
        variant="icon"
        className={`text-primary font-gilroy text-[14px] font-medium leading-[16px] gap-0 sm:gap-2`}
        onClick={() => navigate(routes.settings)}
      >
        <SettingsIcon className="w-[20px] h-[20px]" />
        <p className="hidden sm:block">
          {String(L.translate("Sidebar.settings"))}
        </p>
      </Button>
    </div>
  );
}

function LoginMenu() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <div className={`flex ${isMobile ? "gap-[4px]" : "gap-[10px]"} ml-4`}>
      <Button
        variant="outline"
        className={`${
          isMobile ? "text-[12px]" : "text-[14px]"
        } rounded-[10px] font-gilroy font-medium leading-[24px]`}
        onClick={() => navigate(routes.login)}
      >
        {String(L.translate("Sidebar.login"))}
      </Button>
      <Button
        variant="solid"
        className={`${
          isMobile ? "text-[12px]" : "text-[14px]"
        } rounded-[10px] font-gilroy font-medium leading-[24px] bg-active text-white hover:bg-main`}
        onClick={() => navigate(routes.register)}
      >
        {String(L.translate("Sidebar.register"))}
      </Button>
    </div>
  );
}

interface HeaderProps {
  isCollapse?: boolean;
  showHamburger?: boolean;
  onClickHamburger?: () => void;
}

export default function Header({
  isCollapse,
  showHamburger = false,
  onClickHamburger,
}: HeaderProps) {
  const { currentTheme, changeCurrentTheme } = useContext(ThemeContext);
  const isDarkMode = currentTheme === "dark";
  const navigate = useNavigate();
  const { isAuthorized } = useAuth();

  const [isMobile, setIsMobile] = useState<boolean>(false);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <header
      className={`${
        !isAuthorized && "border-b border-light-300"
      } app-header sticky top-0 z-50 flex h-16 w-full items-center justify-between bg-whole-gray px-4 ltr:left-0 rtl:right-0 dark:border-dark-300 dark:bg-dark-250 sm:h-[70px]`}
    >
      <div className="flex items-center gap-2 cursor-pointer flex-shrink-0">
        <a
          onClick={() => {
            navigate(routes.home);
          }}
        >
          <img
            src={isMobile ? "/logo192.png" : "/logo512.png"}
            className={`${
              isMobile ? "w-[32.78px] h-[32.78px]" : "w-[44px] h-[44px]"
            } object-contain`}
          />
        </a>
      </div>
      <div
        className={`relative flex items-center justify-center ${
          isMobile ? "gap-[8px]" : "gap-[12px]"
        }`}
      >
        {/* <ThemeSwitcher /> */}
        {isAuthorized ? <AuthorizedMenu /> : <LoginMenu />}
        {showHamburger && (
          <Hamburger
            isToggle={isCollapse}
            onClick={onClickHamburger}
            className="flex sm:hidden text-primary"
          />
        )}
        <LangSwitcher />
      </div>
    </header>
  );
}
