export const SquareIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_714)">
        <path
          d="M15.8333 0H4.16667C3.0616 0 2.00179 0.438987 1.22039 1.22039C0.438987 2.00179 0 3.0616 0 4.16667L0 15.8333C0 16.3805 0.107774 16.9223 0.317169 17.4278C0.526563 17.9334 0.833478 18.3927 1.22039 18.7796C2.00179 19.561 3.0616 20 4.16667 20H15.8333C16.3805 20 16.9223 19.8922 17.4278 19.6828C17.9334 19.4734 18.3927 19.1665 18.7796 18.7796C19.1665 18.3927 19.4734 17.9334 19.6828 17.4278C19.8922 16.9223 20 16.3805 20 15.8333V4.16667C20 3.61949 19.8922 3.07768 19.6828 2.57215C19.4734 2.06663 19.1665 1.6073 18.7796 1.22039C18.3927 0.833478 17.9334 0.526563 17.4278 0.317169C16.9223 0.107774 16.3805 0 15.8333 0ZM18.3333 15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V4.16667C1.66667 3.50363 1.93006 2.86774 2.3989 2.3989C2.86774 1.93006 3.50363 1.66667 4.16667 1.66667H15.8333C16.4964 1.66667 17.1323 1.93006 17.6011 2.3989C18.0699 2.86774 18.3333 3.50363 18.3333 4.16667V15.8333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_714">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
