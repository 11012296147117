import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect, ChangeEvent } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { AngleDownSmallIcon } from "../icons/angle-down-small-icon";
import { GreenCheckIcon } from "../icons/green-check-icon";
import { capitalizeFirstLetter, textSearchValid } from "../../lib/utils";
import { SearchIcon } from "../icons/search-icon";
import L from "i18n-react";
import { CloseCircleIcon } from "../icons/close-circle-icon";

type DropdownWithSearchWithoutLabelProps = {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  data: any[];
  value?: number;
  onChange?: any;
  required?: boolean;
};

const DropdownWithSearchWithoutLabel = forwardRef<
  HTMLDivElement,
  DropdownWithSearchWithoutLabelProps
>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      searchPlaceholder = "",
      inputClassName = "bg-gray-modern-100",
      data = [],
      value = -1,
      onChange,
      required,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState<any[]>(data);

    useEffect(() => {
      setSearchText("");
      setSelectedId(value);
      setFilteredData(data);
    }, [value, data]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      if (selectedIDRef.current) {
        setTimeout(
          () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
          100
        );
      }
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      const currentText = e.target.value;
      setSearchText(currentText);

      if (currentText.length === 0) {
        setFilteredData(data);
      } else {
        setFilteredData(
          data.filter((item) =>
            textSearchValid(
              (
                item.name ?? `${item.first_name ?? ""} ${item.last_name ?? ""}`
              ).toLowerCase(),
              currentText.toLowerCase(),
              "0"
            )
          )
        );
      }
    };

    const onRemovedSelected = () => {
      setTimeout(() => {
        setSelectedId(-1);
        onChange(-1);
        selectedIDRef.current = -1;
      }, 300);
    };

    return (
      <div className={className} ref={containerRef}>
        <label className="relative block text-[16px] font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <Menu>
            <MenuButton
              ref={btnRef}
              className={classNames(
                "relative h-[44px] placeholder-gray-modern-400 w-full appearance-none text-[14px] font-gilroy rounded-[8px] px-4 py-2 text-dark ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-gray-modern-400" : "text-primary"
                    } text-left pr-12`}
                  >
                    {data.filter((item) => item.id == selectedId).length > 0
                      ? data.filter((item) => item.id == selectedId)[0].name ??
                        `${capitalizeFirstLetter(
                          data.filter((item) => item.id == selectedId)[0]
                            .first_name ?? ""
                        )} ${capitalizeFirstLetter(
                          data.filter((item) => item.id == selectedId)[0]
                            .last_name ?? ""
                        )}`
                      : placeholder}
                  </p>
                  <span className="absolute top-0 flex h-full w-[32px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <AngleDownSmallIcon
                      className={`text-primary h-auto w-4 ${
                        open ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </span>
                </>
              )}
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                ref={menuRef}
                className={`max-h-[230px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-0 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
              >
                {data.length > 0 && (
                  <div className="w-full px-4 py-2 sticky top-0 bg-white">
                    <input
                      className="focus:border-active h-[44px] mt-2 placeholder-other w-full appearance-none text-[16px] font-gilroy font-medium rounded-[10px] border-[1px] border-opacity-50 border-secondary bg-transparent px-8 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] focus:ring-active"
                      type="text"
                      placeholder={searchPlaceholder}
                      value={searchText}
                      onChange={onSearchTextChange}
                    />
                    <SearchIcon className="absolute left-[24px] top-[30px] w-[16px] h-[16px] text-secondary" />
                  </div>
                )}
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    /* Use the `active` state to conditionally style the active item. */
                    <MenuItem key={index}>
                      {({ active, close }) => (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (item.id == selectedId) {
                              close();
                              return;
                            }

                            setSelectedId(item.id);
                            setTimeout(() => onChange(item.id), 300);
                            selectedIDRef.current = index;

                            close();
                          }}
                          className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 text-primary hover:bg-active group"
                          style={{ width: containerWidth }}
                        >
                          <p className="text-primary text-[16px] font-medium font-gilroy group-hover:text-white">
                            {item.name ??
                              `${capitalizeFirstLetter(
                                item.first_name ?? ""
                              )} ${capitalizeFirstLetter(
                                item.last_name ?? ""
                              )}`}
                          </p>
                          <GreenCheckIcon
                            className={`${
                              selectedId === item.id ? "block" : "hidden"
                            } w-[20px] h-[20px]`}
                          />
                        </button>
                      )}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={-1}>
                    <div
                      className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 text-primary"
                      style={{ width: containerWidth }}
                    >
                      <p className="text-[14px] font-gilroy">
                        {L.translate("Base.nothing")}
                      </p>
                    </div>
                  </MenuItem>
                )}
              </MenuItems>
            </Transition>
          </Menu>
          {selectedId >= 0 && (
            <button
              className="absolute top-[6px] right-[36px] w-[32px] h-[32px] flex items-center justify-center text-secondary hover:text-active"
              onClick={onRemovedSelected}
            >
              <CloseCircleIcon className="[w-16px] h-[16px]" />
            </button>
          )}
        </label>

        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownWithSearchWithoutLabel.displayName = "DropdownWithSearchWithoutLabel";
export default DropdownWithSearchWithoutLabel;
