import { FC, useContext, useEffect, useState } from "react";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import { AccountNames, EventStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import { EventDataType } from "src/lib/data-types";
import {
  Checkbox,
  CheckboxOptionType,
  Dropdown,
  Empty,
  MenuProps,
  Space,
  Table,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  capitalizeFirstLetter,
  getAccountName,
  getContractStatusTextColor,
  getEventStatusColor,
  getEventStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import { SortIcon } from "src/components/icons/sort-icon";
import { SearchQueryOptions, UserType } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { UserIcon } from "src/components/icons/user-icon";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import {
  ColumnsEventsAdmin,
  ColumnsEventsBasicAdmin,
} from "src/lib/table-column-list";
import { useModalAction } from "src/components/modal-views/context";
import { FileEditIcon } from "src/components/icons/file-edit-icon";
import useAuth from "src/hooks/use-auth";
import axios from "axios";
import { ConfigValue } from "src/config";
import { API_ENDPOINTS } from "src/api/endpoints";
import { getAuthToken } from "src/api/token-util";
import { ExcelIcon } from "src/components/icons/excel-icon";
import { CrossIcon } from "src/components/icons/cross-icon";

const AdminEventsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();

  const { getCurrentUser } = useAuth();
  const userType = getCurrentUser().user_type ?? "";

  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "created_at",
    direction: "desc",
    event_name: "",
    event_date: "",
    client_name: "",
    status: -1,
  });
  const { openModal } = useModalAction();
  const [admins, setAdmins] = useState<{ id: number; name: string }[]>([]);
  const [isDownloading, setDownloading] = useState(false);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<EventDataType[]>([]);
  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    language,
    geoPosition,
  } = useContext(GlobalContext);

  const { mutate: getAdmins, isLoading: isLoadingAdmins } = useMutation(
    client.users.basicAdminUsersAll,
    {
      onSuccess: (data) => {
        const fetchedAdmins = data.results.map((item) => ({
          id: item.id + 10,
          name: `${capitalizeFirstLetter(
            item.first_name ?? ""
          )} ${capitalizeFirstLetter(item.last_name ?? "")}`,
        }));
        setAdmins([...AccountNames, ...fetchedAdmins]);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;

    getAdmins();

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_name,
    searchParams.event_date,
    searchParams.client_name,
    searchParams.status,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 1124 ? true : false);
  }

  const onCreateNewEvent = () => {
    navigate(routes.createEvent);
  };

  const { mutate: deleteEvent, isLoading: isDeleting } = useMutation(
    client.events.delete,
    {
      onSuccess: (data) => {
        getList(searchParams);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_event")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(client.events.list, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          name: item.name,
          invoice_no: item.invoice_no ?? "",
          description: item.description,
          account: item.account ?? -1,
          quote_no: item.quote_no,
          invoice_descr: item.invoice_descr,
          city: item.city,
          contract: item.contract,
          contract_received: item.contract_received,
          number_of_collaborators: item.number_of_collaborators,
          numbers_per_day: item.numbers_per_day,
          contract_status: item.number_of_collaborators
            ? `${item.contract_received}/${item.number_of_collaborators}`
            : "",
          location: item.location ?? "",
          coordinate: item.coordinate ?? "",
          start_date: item.start_date ?? "",
          end_date: item.end_date ?? "",
          status: item.status,
          bid_status: item.bid_status,
          bid_comp: item.bid_comp ? `${item.bid_comp}` : "-",
          bid_reimburse: item.bid_reimburse ? `${item.bid_reimburse}` : "",
          bid_contract_signed: item.bid_contract_signed,
          bid_contract_pdf: item.bid_contract_pdf ?? "",
          bid_notes: item.bid_notes ?? "",
          bid_event_date: item.bid_event_date,
          bid_payment_date: item.bid_payment_date,
          client_name: item.client.name,
          client: item.client,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_events")));
      }
      setIsAlertOpened(true);
    },
  });

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const onClickedEvent = (eventData: EventDataType) => {
    if (eventData.start_date.length == 0) {
      //Event should be planned
      navigate(routes.planJob, { state: eventData });
    } else {
      //View event details
      navigate(routes.eventPreview, { state: eventData });
    }
  };

  const onRefreshList = () => {
    getList(searchParams);
  };

  const onDelete = (eventID: number) => {
    openModal("CONFIRM", { payload: eventID, callback: onConfirmDelete });
  };

  const onConfirmDelete = (eventID: number) => {
    console.log("event id - ", eventID);
    deleteEvent(eventID);
  };

  const onEdit = (eventID: number) => {
    const selectedEvents = dataSource.filter((item) => item.id == eventID);
    if (selectedEvents.length == 0) return;
    openModal("EVENT_EDIT", {
      payload: {
        event: selectedEvents[0],
      },
      refreshCallback: onRefreshList,
    });
  };

  const onExport = async (eventID: number, eventName: string) => {
    setDownloading(true);

    try {
      const response = await axios.get(
        `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/${API_ENDPOINTS.EVENT_INFO_EXPORT_BY_ID}/${eventID}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${getAuthToken() ?? ""}`,
          },
        }
      );

      setDownloading(false);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${eventName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setDownloading(false);

      console.error("Error downloading EXCEL file:", error);
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.error_download_excel")));
      setIsAlertOpened(true);
    }
  };

  const columns: ColumnsType<EventDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event")),
          "name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "name",
      className: "w-auto",
      key: "name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.cost")),
          "contract",
          searchParams,
          setSearchParams
        ),
      dataIndex: "contract",
      className: "w-[120px]",
      key: "contract",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? `€ ${makeNumberWithFloatingDigits(value, 2)}` : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.invoice_no")),
          "invoice_no",
          searchParams,
          setSearchParams
        ),
      dataIndex: "invoice_no",
      className: "w-[120px]",
      key: "invoice_no",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value.length == 0 ? "-" : value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.client")),
          "client_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.location")),
          "location",
          searchParams,
          setSearchParams
        ),
      dataIndex: "location",
      className: "w-auto",
      key: "location",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px] line-clamp-3">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_received")}
        </p>
      ),
      dataIndex: "contract_status",
      className: "w-auto",
      key: "contract_status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          {value.length > 0 && (
            <div className="flex flex-row gap-2 items-center">
              <UserIcon
                className={`${getContractStatusTextColor(
                  value
                )} w-[16px] h-[16px]`}
              />
              <p
                className={`${getContractStatusTextColor(
                  value
                )} font-gilroy text-[13px] sm:text-[14px]`}
              >
                {value}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.start_date")),
          "start_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "start_date",
      className: "w-[120px]",
      key: "start_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.end_date")),
          "end_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "end_date",
      className: "w-[120px]",
      key: "end_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.account")}
        </p>
      ),
      dataIndex: "account",
      className: "w-[120px]",
      key: "account",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value > 0 ? getAccountName(value, admins) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: language == "EN" ? "w-[172px]" : "w-[200px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getEventStatusColor(value) }}
          >
            {L.translate(getEventStatusName(value))}
          </p>
        </div>
      ),
    },
  ];

  const columnsForBasic: ColumnsType<EventDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event")),
          "name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "name",
      className: "w-auto",
      key: "name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.client")),
          "client_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.location")),
          "location",
          searchParams,
          setSearchParams
        ),
      dataIndex: "location",
      className: "w-auto",
      key: "location",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px] line-clamp-3">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_received")}
        </p>
      ),
      dataIndex: "contract_status",
      className: "w-auto",
      key: "contract_status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          {value.length > 0 && (
            <div className="flex flex-row gap-2 items-center">
              <UserIcon
                className={`${getContractStatusTextColor(
                  value
                )} w-[16px] h-[16px]`}
              />
              <p
                className={`${getContractStatusTextColor(
                  value
                )} font-gilroy text-[13px] sm:text-[14px]`}
              >
                {value}
              </p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.start_date")),
          "start_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "start_date",
      className: "w-[120px]",
      key: "start_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.end_date")),
          "end_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "end_date",
      className: "w-[120px]",
      key: "end_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.account")}
        </p>
      ),
      dataIndex: "account",
      className: "w-[120px]",
      key: "account",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value >= 0 ? getAccountName(value, admins) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: language == "EN" ? "w-[172px]" : "w-[200px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getEventStatusColor(value) }}
          >
            {L.translate(getEventStatusName(value))}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<EventDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col sm:flex-row gap-4 items-start justify-between">
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {userType == UserType.SUPER_ADMIN
                      ? selData.invoice_no.length == 0
                        ? selData.name
                        : `${selData.name} - ${selData.invoice_no}`
                      : selData.name}
                  </p>
                </div>
                {userType == UserType.SUPER_ADMIN && (
                  <div className="flex flex-row items-start justify-center gap-2">
                    <p className="text-secondary text-[14px] font-gilroy font-medium">
                      {`${L.translate("CommonForm.cost")}:`}
                    </p>
                    <p className="text-primary text-[14px] font-gilroy font-medium">
                      {selData.contract
                        ? `€${makeNumberWithFloatingDigits(
                            selData.contract,
                            2
                          )}`
                        : "-"}
                    </p>
                  </div>
                )}
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.client")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.client_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.contract_received")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    {selData.contract_status.length > 0 && (
                      <div className="flex flex-row gap-2 items-center">
                        <UserIcon
                          className={`${getContractStatusTextColor(
                            selData.contract_status
                          )} w-[16px] h-[16px]`}
                        />
                        <p
                          className={`${getContractStatusTextColor(
                            selData.contract_status
                          )} font-gilroy text-[14px] font-medium`}
                        >
                          {selData.contract_status}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.start_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.start_date
                      ? makeFormattedDateString(selData.start_date)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.end_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.end_date
                      ? makeFormattedDateString(selData.end_date)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.status")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getEventStatusColor(selData.status),
                      }}
                    >
                      {L.translate(getEventStatusName(selData.status))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.location")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.location}
              </p>
            </div>
            <div className="flex flex-row items-center justify-center gap-2 w-full md:w-[50%]">
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[44px] rounded-[8px] hover:bg-red-400 bg-error"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                <CrossIcon className="text-white w-4 h-4" />
                {L.translate("Base.delete")}
              </Button>
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[44px] rounded-[8px] hover:bg-main bg-active"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(selData.id);
                }}
              >
                <FileEditIcon className="text-white w-4 h-4" />
                {L.translate("Base.edit")}
              </Button>
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[44px] rounded-[8px] hover:bg-error-100 bg-purple"
                onClick={(e) => {
                  e.stopPropagation();
                  onExport(selData.id, selData.name);
                }}
              >
                <ExcelIcon className="text-white w-4 h-4" />
                {L.translate("Base.export")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = (
    userType == UserType.SUPER_ADMIN
      ? ColumnsEventsAdmin
      : ColumnsEventsBasicAdmin
  ).map((item) => item.value as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  let newColumns = (
    userType == UserType.SUPER_ADMIN ? columns : columnsForBasic
  ).map((item) => ({
    ...item,
    hidden: !checkedList.includes(item.key as string),
  }));

  newColumns.push({
    hidden: false,
    title: () => <p className="hidden" />,
    dataIndex: "action",
    className: "w-[50px]",
    render: (_, record: { key: React.Key; id: number; name: string }) => (
      <div className="flex flex-row items-center justify-start gap-2">
        <Button
          variant="icon"
          className="bg-error !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(record.id);
          }}
        >
          <CrossIcon className="text-white w-4 h-4" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("Base.delete")}
          </span>
        </Button>
        <Button
          variant="icon"
          className="bg-active !text-white hover:!bg-main text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onEdit(record.id);
          }}
        >
          <FileEditIcon className="text-white w-4 h-4" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("Base.edit")}
          </span>
        </Button>
        <Button
          variant="icon"
          className="bg-purple !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            onExport(record.id, record.name);
          }}
        >
          <ExcelIcon className="text-white w-4 h-4" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("Base.export")}
          </span>
        </Button>
      </div>
    ),
  });

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("Sidebar.current_events")}
          </div>
          <Button
            variant="solid"
            className="rounded-[8px] text-[12px] font-gilroy leading-[24px] bg-active text-white hover:bg-main"
            onClick={onCreateNewEvent}
          >
            <CalendarPlusIcon className="w-[16px] h-[16px] text-white" />
            <span className="text-[14px] font-gilroy uppercase">
              {L.translate("Sidebar.new_event")}
            </span>
          </Button>
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.event_name"))}
            className="w-full"
            value={searchParams.event_name}
            showClose={searchParams.event_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, event_name: "" })
            }
            onChange={(e) =>
              setSearchParams({ ...searchParams, event_name: e.target.value })
            }
          />
          <MonthPicker
            placeholder={String(L.translate("CommonForm.filter_by_month"))}
            value={searchParams.event_date ?? ""}
            onChange={(strDate?: string) =>
              setSearchParams({ ...searchParams, event_date: strDate ?? "" })
            }
          />
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.client_name"))}
            className="w-full"
            showClose={searchParams.client_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, client_name: "" })
            }
            value={searchParams.client_name}
            onChange={(e) =>
              setSearchParams({ ...searchParams, client_name: e.target.value })
            }
          />
          <DropdownWithoutLabel
            placeholder={String(L.translate("CommonForm.status"))}
            className="w-full"
            data={[
              { id: -1, name: String(L.translate("CommonForm.status")) },
              ...EventStatus.map((item) => ({
                ...item,
                name: String(L.translate(item.name)),
              })),
            ]}
            onChange={(e: number) =>
              setSearchParams({ ...searchParams, status: e })
            }
          />
        </div>
        <div className="mt-4 relative w-full">
          {!isMobile && (
            <Checkbox.Group
              className="mt-2 gap-4 border-main border-opacity-60 border-[1px] rounded-[8px] px-4 py-2"
              value={checkedList}
              options={
                (userType == UserType.SUPER_ADMIN
                  ? ColumnsEventsAdmin
                  : ColumnsEventsBasicAdmin
                ).map((item) => ({
                  ...item,
                  label: L.translate(item.label),
                })) as CheckboxOptionType[]
              }
              onChange={(value) => {
                setCheckedList(value as string[]);
              }}
            />
          )}
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p className="font-gilroy-semibold text-secondary text-[14px]">
                      {L.translate("Base.no_data")}
                    </p>
                  }
                />
              ),
            }}
            showSorterTooltip={false}
            columns={isMobile ? mobileColumns : newColumns}
            dataSource={dataSource}
            loading={isLoading || isLoadingAdmins || isDeleting}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onClickedEvent(record),
              };
            }}
            pagination={{
              total,
              current: searchParams.page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminEventsPage;
