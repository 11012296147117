import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MonthCalendar from "src/components/calendar/month-view";
import Button from "src/components/ui/button";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import InputWithoutLabel from "src/components/ui/input-without-label";
import PlaceInput from "src/components/ui/place-input";
import routes from "src/config/routes";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Spin } from "antd";
import GlobalContext from "src/context/global-context";
import { Event, UserType } from "src/api/types";
import { capitalizeFirstLetter, detectContinuousDates } from "src/lib/utils";
import L from "i18n-react";
import CollabNumbersPerDay from "src/components/ui/number-selection-per-day";
import useAuth from "src/hooks/use-auth";
import { ExclamationIcon } from "src/components/icons/exclamation-icon";

const PlanJobPage: FC = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();

  const { getCurrentUser } = useAuth();
  const permissions = getCurrentUser().permissions ?? "";
  const userType = getCurrentUser().user_type ?? "";

  const selectedEvent = location.state;

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [clientName, setClientName] = useState("");
  const [contract, setContract] = useState("");
  const [numbers_per_day, setNumbersPerDay] = useState("");
  const [events, setEvents] = useState<Event[]>([]);

  const schema = yup
    .object({
      event_id: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      date: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      numbers_per_day_filled: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      location: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      coordinate: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      event_id: selectedEvent?.id ?? undefined,
      location: "",
      coordinate: "",
    },
  });
  const eventID = watch("event_id");
  const eventDates = watch("date");
  const eventLocation = watch("location");
  const eventCoordinate = watch("coordinate");

  const { mutate: getEvents, isLoading: isLoadingEvents } = useMutation(
    client.events.all,
    {
      onSuccess: (data) => {
        setEvents(data.results);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;

    getEvents("all");
  }, [isMounted, selectedEvent]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    const selEvents = events.filter((item) => item.id == eventID);
    selEvents.length > 0 && setClientName(selEvents[0].client.name);
    selEvents.length > 0 && setContract(`${selEvents[0].contract}`);
  }, [events, eventID]);

  const { mutate: createEvent, isLoading } = useMutation(
    client.events.register,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.event_planned");
        setIsAlertOpened(true);

        setTimeout(() => navigate(routes.calendar), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_plan_event")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onConfirmEvent = (data: FormData) => {
    /*
    if (!detectContinuousDates(data.date.split(","))) {
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("CommonForm.no_continuous_dates")));
      setIsAlertOpened(true);
      return;
    }
    */

    let totalNumbers = 0;
    const items = numbers_per_day.split(",");
    items.map((item) => {
      const numbers = item.split(":");
      if (numbers.length >= 2) totalNumbers += Number(numbers[1]);
    });

    createEvent({
      ...data,
      number_of_collaborators: totalNumbers,
      numbers_per_day,
    });
  };

  const onSelectDate = (strDate: string) => {
    setValue("date", strDate, {
      shouldValidate: true,
    });
  };

  if (
    userType == UserType.BASIC_ADMIN &&
    !(permissions.includes("Plan job") || permissions.includes("Pianifica"))
  ) {
    return (
      <div className="w-full h-full bg-whole-gray relative">
        <div className="w-full h-full flex items-center justify-center gap-4">
          <ExclamationIcon className="h-8 w-8 text-primary" />
          <span className="font-gilroy text-[20px] text-primary">
            {L.translate("Errors.no_permission")}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full bg-whole-gray">
      {isLoadingEvents && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}

      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("Sidebar.plan_job")}
        </div>
        <div className="mt-4 flex flex-col sm:flex-row gap-16 sm:gap-8">
          <div className="w-full sm:w-[45%] md:w-[30%]">
            <MonthCalendar
              onSelectDate={onSelectDate}
              error={errors.date && (errors.date.message as string)}
            />
          </div>
          <div className="w-full sm:w-[50%] flex flex-col gap-4">
            <div className="text-primary font-gilroy font-semibold text-[16px]">
              {L.translate("CommonForm.main_details")}
            </div>
            <Controller
              name="event_id"
              control={control}
              render={({ field }) => (
                <DropdownWithoutLabel
                  label={String(L.translate("CommonForm.event_name"))}
                  className="w-full"
                  required
                  data={events}
                  error={errors.event_id && (errors.event_id.message as string)}
                  {...field}
                />
              )}
            />
            <InputWithoutLabel
              disabled
              required
              label={String(L.translate("CommonForm.client_name"))}
              className="w-full"
              value={clientName}
            />
            {/*
              <InputWithoutLabel
                type="number"
                disabled
                label={String(L.translate("CommonForm.contract"))}
                className="w-full"
                value={contract}
              />
            */}
            {eventDates && eventDates.length > 0 && (
              <Controller
                name="numbers_per_day_filled"
                control={control}
                render={({ field }) => (
                  <CollabNumbersPerDay
                    required
                    dates={eventDates}
                    currentValue={numbers_per_day}
                    callback={setNumbersPerDay}
                    label={String(
                      L.translate("CommonForm.number_of_collaborators_per_day")
                    )}
                    className="w-full"
                    {...field}
                  />
                )}
              />
            )}

            <div className="text-primary font-gilroy font-semibold text-[16px] mt-4">
              <span className="text-[14px] text-error font-gilroy">* </span>
              {L.translate("CommonForm.event_location")}
            </div>
            <PlaceInput
              required
              placeholder={String(L.translate("Base.type_address_here"))}
              className="w-full"
              onPicked={(locationInfo: {
                location: string;
                coordinate: string;
              }) => {
                setValue("location", locationInfo.location, {
                  shouldValidate: true,
                });
                setValue("coordinate", locationInfo.coordinate, {
                  shouldValidate: true,
                });
              }}
            />

            <div
              className={`flex flex-col gap-8 mt-4 ${
                isMobile
                  ? "mb-4"
                  : eventDates && eventDates.length > 0
                  ? "mb-4"
                  : "mb-24"
              }`}
            >
              <Button
                variant="validate"
                type="submit"
                className="rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px]"
                onClick={handleSubmit(onConfirmEvent)}
                disabled={!isValid || isLoadingEvents || isLoading}
                isLoading={isLoading}
              >
                {L.translate("CommonForm.confirm_event")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanJobPage;
