import { FC, useContext, useEffect, useState, useRef } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import L from "i18n-react";
import { CollabDataType } from "src/lib/data-types";
import { useModalAction } from "src/components/modal-views/context";
import { ConfigValue } from "src/config";
import ImageWithLoading from "src/components/ui/image-with-loading";
import { Spin } from "antd";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { calculateDistance, capitalizeFirstLetter } from "src/lib/utils";
import UserPlaceholder from "../../assets/images/placeholder.png";
import CitySelection from "../ui/city-selection";
import Dropdown from "../ui/dropdown";
import { Langauges, Langauges_IT, ShoeSizes } from "src/lib/constants";
import moment from "moment";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { ClusteredMapMarkers } from "../map/clutered-markers";
import { LocationArrowIcon } from "../icons/location-arrow-icon";
import { MarkerIcon } from "../icons/marker-icon";
import CollabFilterSection from "../filter/collab";

const CollaboratorsMapView = ({
  isMoreFilters,
}: {
  isMoreFilters: boolean;
}) => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const [selectedCollabs, setSelectedCollabs] = useState<number[]>([]);
  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    geoPosition,
    language,
  } = useContext(GlobalContext);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollabDataType[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    CollabDataType[]
  >([]);

  //const mapRef = useRef<MapRef>(null);
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [selectedCollabID, setSelectedCollabID] = useState(-1);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    getList();
  }, [isMounted]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 960 ? true : false);
  }
  const onRefreshList = () => {
    getList();
  };

  const { mutate: getList, isLoading } = useMutation(
    client.users.collabUsersAll,
    {
      onSuccess: (data) => {
        setTotal(data.results.length);
        setDataSource(
          data.results.map((item, index) => {
            let lat: number = 0;
            let lng: number = 0;

            if (item.location) {
              const positions = item.location.split(",");
              if (positions.length >= 2) {
                lat = Number(positions[0]);
                lng = Number(positions[1]);
              }
            }

            return {
              key: index,
              id: item.id,
              first_name: item.first_name ?? "",
              last_name: item.last_name ?? "",
              email: item.email,
              lat: lat,
              lng: lng,
              name: `${capitalizeFirstLetter(
                item.first_name ?? ""
              )} ${capitalizeFirstLetter(item.last_name ?? "")}`,
              cv: item.collaborator?.cv ?? "",
              main_photo: item.collaborator?.main_photo ?? "",
              photo2: item.collaborator?.photo2 ?? "",
              photo3: item.collaborator?.photo3 ?? "",
              photo4: item.collaborator?.photo4 ?? "",
              photo5: item.collaborator?.photo5 ?? "",
              photo6: item.collaborator?.photo6 ?? "",
              photo7: item.collaborator?.photo7 ?? "",
              photo8: item.collaborator?.photo8 ?? "",
              photo9: item.collaborator?.photo9 ?? "",
              sex: item.collaborator ? item.collaborator!.info.sex : -1,
              height: item.collaborator ? item.collaborator!.info.height : "",
              shoe_size: item.collaborator
                ? item.collaborator!.info.shoe_size
                : "",
              instagram: item.collaborator
                ? item.collaborator!.info.instagram
                : "",
              telephone_number: item.collaborator
                ? item.collaborator!.info.telephone_number
                : "",
              info: item.collaborator ? item.collaborator!.info : undefined,
              status: item.status,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          })
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_collaborator")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  /*
  const onTappedMarker = (e: any, data: CollabDataType) => {
    const location = e.target.getLngLat();
    moveLocation(location, data);
  };

  const moveLocation = (
    location: { lat: number; lng: number },
    data: CollabDataType
  ) => {
    mapRef.current && mapRef.current.panTo(location);
    setSelectedPhotos([]);

    setTimeout(() => {
      setSelectedLocation({ ...location });

      if (data.info) {
        setSelectedPhotos([
          data.main_photo,
          data.photo2,
          data.photo3,
          data.photo4,
          data.photo5,
        ]);
      } else {
        setAlertTitle("Alert.error");
        setAlertText(String(L.translate("Errors.no_profile")));
        setIsAlertOpened(true);
      }
    }, 600);
  };
    */

  const resetSelected = () => {
    setSelectedCollabs([]);
    setSelectedLocation(null);
    setSelectedCollabID(-1);
  };

  const onTappedItem = (nID: number) => {
    const selectedItem = filteredDataSource.filter((item) => item.id == nID);
    if (selectedItem.length == 0) return;

    const collaboratorUser = selectedItem[0];
    if (collaboratorUser.lat == 0 && collaboratorUser.lng == 0) {
      if (selectedCollabs.length > 0 && selectedCollabs[0] == nID) {
        setSelectedCollabs([]);
        setSelectedLocation(null);
        setSelectedCollabID(-1);
      } else {
        setSelectedCollabs([nID]);
        setSelectedCollabID(nID);
        setSelectedLocation({
          lat: selectedItem[0].lat,
          lng: selectedItem[0].lng,
        });
      }

      //setAlertTitle("Alert.error");
      //setAlertText(String(L.translate("Errors.no_location")));
      //setIsAlertOpened(true);
    } else {
      if (selectedCollabs.length > 0 && selectedCollabs[0] == nID) {
        setSelectedCollabs([]);
        setSelectedLocation(null);
        setSelectedCollabID(-1);
      } else {
        setSelectedCollabs([nID]);
        setSelectedCollabID(nID);
        setSelectedLocation({
          lat: selectedItem[0].lat,
          lng: selectedItem[0].lng,
        });
      }
    }

    setTimeout(() => setSelectedLocation(null), 600);
  };

  const makeMapView = () => {
    return (
      <APIProvider apiKey={ConfigValue.GOOGLE_MAPS_API}>
        <Map
          mapId={ConfigValue.GOOGLE_MAP_ID}
          defaultCenter={{ lat: geoPosition.lat, lng: geoPosition.lng }}
          center={selectedLocation ? selectedLocation : undefined}
          defaultZoom={15}
          zoom={selectedLocation ? 15 : undefined}
          gestureHandling={"greedy"}
          style={{
            width: "100%",
            height: isMobile ? "80vh" : "calc(100vh - 210px)",
            borderTopRightRadius: isMobile ? 0 : 8,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: isMobile ? 8 : 0,
            overflow: "clip",
          }}
          disableDefaultUI
        >
          {filteredDataSource && (
            <ClusteredMapMarkers
              items={filteredDataSource}
              selectedID={selectedCollabID}
              resetSelectedID={resetSelected}
              onRefreshList={onRefreshList}
            />
          )}
        </Map>
      </APIProvider>
    );
    /*
    return (
      <Map
        ref={mapRef}
        mapboxAccessToken={ConfigValue.MAPBOX_ACCESS_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        style={{
          width: "100%",
          height: isMobile ? "80vh" : "calc(100vh - 210px)",
          borderTopRightRadius: isMobile ? 0 : 8,
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: isMobile ? 8 : 0,
        }}
        initialViewState={{
          longitude: geoPosition.lng,
          latitude: geoPosition.lat,
          zoom: 8,
        }}
      >
        {filteredDataSource.map(
          (item) =>
            item.lat != 0 &&
            item.lng != 0 && (
              <Marker
                key={item.id}
                longitude={item.lng}
                latitude={item.lat}
                onClick={(e) => onTappedMarker(e, item)}
              >
                <div className="flex items-center justify-center bg-active w-[48px] h-[48px] rounded-full">
                  <LazyLoadImage
                    effect="blur"
                    src={
                      item.main_photo.length == 0
                        ? UserPlaceholder
                        : item.main_photo
                    }
                    className="w-[48px] h-[48px] object-cover rounded-full"
                  />
                </div>
              </Marker>
            )
        )}

        {selectedLocation && selectedPhotos.length > 0 && (
          <Popup
            longitude={selectedLocation.lng}
            latitude={selectedLocation.lat}
            anchor="bottom"
            closeButton={false}
            onClose={() => setSelectedLocation(null)}
            offset={24}
          >
            <div className="flex items-center justify-center bg-white rounded-[8px] font-gilroy-bold text-[14px]">
              <Carousel
                dynamicHeight={false}
                showArrows={false}
                showThumbs={false}
                autoPlay={true}
                showStatus={false}
                infiniteLoop={true}
                interval={3000}
                showIndicators={true}
                className="w-full h-full"
              >
                {selectedPhotos.map((item, index) => (
                  <div
                    className="relative w-full h-full"
                    key={`thumbnail_${index}`}
                  >
                    <ImageWithLoading
                      src={item}
                      className="object-contain aspect-square bg-gray-modern-100 rounded-[8px]"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </Popup>
        )}
      </Map>
    );
    */
  };

  return (
    <div className="w-full mt-4 relative">
      {isLoading && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}
      <CollabFilterSection
        isMore={isMoreFilters}
        dataSource={dataSource}
        setFilteredDataSource={setFilteredDataSource}
        setSelectedLocation={setSelectedLocation}
      />
      <div className="flex flex-col md:flex-row">
        <div
          className={`${
            isMobile ? "rounded-t-[8px]" : "rounded-l-[8px]"
          } flex flex-col w-full md:max-w-[40%] xl:max-w-[30%] bg-gray-modern-50 overflow-y-auto scrollbar-hide`}
          style={{ height: isMobile ? "80vh" : "calc(100vh - 210px)" }}
        >
          {filteredDataSource.length == 0 && (
            <label className="mt-4 w-full text-center font-gilroy-semibold text-[14px] text-secondary">
              {L.translate("CommonForm.no_collaborators")}
            </label>
          )}
          {filteredDataSource.map((item, index) => (
            <div
              key={`map-list-${index}`}
              className={`${selectedCollabs.includes(item.id) && "bg-active"} ${
                index < filteredDataSource.length - 1 &&
                "border-b-[1px] border-success-100"
              } flex flex-row items-center gap-2 p-4 hover:bg-main hover:bg-opacity-30 group`}
              onClick={() => onTappedItem(item.id)}
            >
              <ImageWithLoading
                isRoundedFull={true}
                src={
                  item.main_photo.length > 0 ? item.main_photo : UserPlaceholder
                }
                className="flex-shrink-0 w-[48px] h-[48px] object-cover rounded-full"
              />
              <div className="flex flex-col items-start">
                <label
                  className={`${
                    selectedCollabs.includes(item.id)
                      ? "text-white"
                      : "text-primary"
                  } text-[16px] font-gilroy-bold group-hover:text-white`}
                >
                  {item.name}
                </label>
                <div className="flex flex-row sm:flex-col lg:flex-row gap-4 sm:gap-0 lg:gap-4">
                  <div className="flex flex-row items-center justify-start gap-1">
                    <label
                      className={`${
                        selectedCollabs.includes(item.id)
                          ? "text-white"
                          : "text-secondary"
                      } text-[12px] font-gilroy group-hover:text-white`}
                    >
                      {`${L.translate(
                        "CollaboratorProfile.height_without_cm"
                      )}:`}
                    </label>
                    <label
                      className={`${
                        selectedCollabs.includes(item.id)
                          ? "text-white"
                          : "text-primary"
                      } text-[12px] font-gilroy-semibold group-hover:text-white`}
                    >
                      {item.height ? `${item.height}cm` : "-"}
                    </label>
                  </div>
                  <div className="flex flex-row items-center justify-start gap-1">
                    <label
                      className={`${
                        selectedCollabs.includes(item.id)
                          ? "text-white"
                          : "text-secondary"
                      } text-[12px] font-gilroy group-hover:text-white`}
                    >
                      {`${L.translate("CollaboratorProfile.size")}:`}
                    </label>
                    <label
                      className={`${
                        selectedCollabs.includes(item.id)
                          ? "text-white"
                          : "text-primary"
                      } text-[12px] font-gilroy-semibold group-hover:text-white`}
                    >
                      {item.info
                        ? `${
                            ShoeSizes.filter(
                              (eachSize) =>
                                eachSize.id == item.info!.italian_size
                            )[0].name
                          }`
                        : "-"}
                    </label>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-center gap-1">
                  <MarkerIcon
                    className={`${
                      selectedCollabs.includes(item.id)
                        ? "text-white"
                        : "text-secondary"
                    } w-4 h-4 mt-1 flex-shrink-0`}
                  />
                  <label
                    className={`${
                      selectedCollabs.includes(item.id)
                        ? "text-white"
                        : "text-primary"
                    } text-[14px] font-gilroy-semibold group-hover:text-white`}
                  >
                    {item.info
                      ? item.info.home_address.replace(/\$\$\$/g, ", ")
                      : "-"}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="relative w-full h-full">{makeMapView()}</div>
      </div>
    </div>
  );
};

export default CollaboratorsMapView;
