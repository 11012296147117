export const GreenCheckIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10" cy="10" r="10" fill="#26B473" />
      <path
        d="M14.7521 8.42669L9.92458 13.1825L9.92374 13.1817C9.60208 13.5042 9.17874 13.665 8.75124 13.665C8.32374 13.665 7.89708 13.5034 7.57124 13.1775L5.25291 10.9309C4.92291 10.61 4.91458 10.0825 5.23541 9.75253C5.55624 9.42169 6.08374 9.41503 6.41374 9.73419L8.74124 11.99L13.5821 7.23919C13.9104 6.91669 14.4379 6.92086 14.7604 7.24836C15.0829 7.57586 15.0804 8.10336 14.7521 8.42669Z"
        fill="white"
      />
      <path
        d="M14.7521 8.42669L9.92458 13.1825L9.92374 13.1817C9.60208 13.5042 9.17874 13.665 8.75124 13.665C8.32374 13.665 7.89708 13.5034 7.57124 13.1775L5.25291 10.9309C4.92291 10.61 4.91458 10.0825 5.23541 9.75253C5.55624 9.42169 6.08374 9.41503 6.41374 9.73419L8.74124 11.99L13.5821 7.23919C13.9104 6.91669 14.4379 6.92086 14.7604 7.24836C15.0829 7.57586 15.0804 8.10336 14.7521 8.42669Z"
        stroke="#E5E7EB"
      />
    </svg>
  );
};
