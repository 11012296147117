import { Fragment, lazy } from "react";
import { MODAL_VIEWS, useModalAction, useModalState } from "./context";
import { Dialog, DialogPanel } from "../ui/dialog";
import { Transition, TransitionChild } from "../ui/transition";
import { CloseCircleIcon } from "../icons/close-circle-icon";
import { Modal as AntModal } from "antd";

const ActionSuccessView = lazy(() => import("../auth/success-modal"));
const DocumentsView = lazy(() => import("../documents-modal"));
const ConfirmView = lazy(() => import("../confirm-modal"));
const AddBasicAdminView = lazy(() => import("../basic-admin-modal/add"));
const EditBasicAdminView = lazy(() => import("../basic-admin-modal/edit"));
const AddClientView = lazy(() => import("../client-modal/add"));
const EditClientView = lazy(() => import("../client-modal/edit"));
const CollaboratorBidView = lazy(() => import("../collaborator-modal"));
const WebCastingCodeView = lazy(() => import("../web-casting-modal"));
const EventDetailsPopupView = lazy(() => import("../events/modal"));
const CompDetailsView = lazy(() => import("../contract/comp-modal"));
const SignContractView = lazy(() => import("../contract/sign-contract-modal"));
const CollaboratorAddView = lazy(() => import("../collaborator-modal/add"));
const EventEditView = lazy(() => import("../events/edit-modal"));
const EventEditDatesView = lazy(() => import("../events/edit-dates-modal"));
const ReorderCollabsView = lazy(() => import("../collaborators/reorder"));
const CollabLeaveReviewView = lazy(
  () => import("../collaborators/review-modal")
);
const ChangeStatusView = lazy(() => import("../events/change-status-modal"));
const CastingPhotosView = lazy(() => import("../casting/photos-modal"));
const JobAdsStatusView = lazy(() => import("../ads/status-modal"));
const CollabPickDatesView = lazy(
  () => import("../collaborators/pick-dates-modal")
);

function renderModalContent(view: MODAL_VIEWS) {
  switch (view) {
    case "ACTION_SUCCESS":
      return <ActionSuccessView />;
    case "DOCUMENTS":
      return <DocumentsView />;
    case "CONFIRM":
      return <ConfirmView />;
    case "ADD_BASIC_ADMIN":
      return <AddBasicAdminView />;
    case "EDIT_BASIC_ADMIN":
      return <EditBasicAdminView />;
    case "ADD_CLIENT":
      return <AddClientView />;
    case "EDIT_CLIENT":
      return <EditClientView />;
    case "COLLABORATOR_BID":
      return <CollaboratorBidView />;
    case "COLLABORATOR_ADD":
      return <CollaboratorAddView />;
    case "WEB_CASTING_CODE":
      return <WebCastingCodeView />;
    case "EVENT_BID_DETAILS":
      return <EventDetailsPopupView />;
    case "COMP_DETAILS":
      return <CompDetailsView />;
    case "SIGN_CONTRACT":
      return <SignContractView />;
    case "EVENT_EDIT":
      return <EventEditView />;
    case "EVENT_EDIT_DATES":
      return <EventEditDatesView />;
    case "REORDER_COLLABS":
      return <ReorderCollabsView />;
    case "LEAVE_REVIEW":
      return <CollabLeaveReviewView />;
    case "CHANGE_STATUS":
      return <ChangeStatusView />;
    case "CASTING_PHOTOS":
      return <CastingPhotosView />;
    case "ADS_STATUS":
      return <JobAdsStatusView />;
    case "COLLAB_PICK_DATES":
      return <CollabPickDatesView />;
    default:
      return null;
  }
}

export default function ModalsContainer() {
  const { view, isOpen } = useModalState();
  const { closeModal } = useModalAction();

  return (
    <AntModal
      open={isOpen}
      centered
      closable={false}
      width={"auto"}
      footer={null}
      onCancel={closeModal}
    >
      {view && renderModalContent(view)}
    </AntModal>
  );
}
