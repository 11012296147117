export const UserLockIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_726)">
        <path
          d="M18.3333 12.1142V10.8333C18.3333 8.995 16.8383 7.5 15 7.5C13.1617 7.5 11.6667 8.995 11.6667 10.8333V12.1142C10.6708 12.6917 10 13.7692 10 15V16.6667C10 18.505 11.495 20 13.3333 20H16.6667C18.505 20 20 18.505 20 16.6667V15C20 13.7692 19.3292 12.6917 18.3333 12.1142ZM13.3333 10.8333C13.3333 9.91417 14.0808 9.16667 15 9.16667C15.9192 9.16667 16.6667 9.91417 16.6667 10.8333V11.6667H13.3333V10.8333ZM18.3333 16.6667C18.3333 17.5858 17.5858 18.3333 16.6667 18.3333H13.3333C12.4142 18.3333 11.6667 17.5858 11.6667 16.6667V15C11.6667 14.0808 12.4142 13.3333 13.3333 13.3333H16.6667C17.5858 13.3333 18.3333 14.0808 18.3333 15V16.6667ZM16.25 15.8333C16.25 16.5233 15.69 17.0833 15 17.0833C14.31 17.0833 13.75 16.5233 13.75 15.8333C13.75 15.1433 14.31 14.5833 15 14.5833C15.69 14.5833 16.25 15.1433 16.25 15.8333ZM6.66667 10C9.42417 10 11.6667 7.7575 11.6667 5C11.6667 2.2425 9.42417 0 6.66667 0C3.90917 0 1.66667 2.2425 1.66667 5C1.66667 7.7575 3.90917 10 6.66667 10ZM6.66667 1.66667C8.505 1.66667 10 3.16167 10 5C10 6.83833 8.505 8.33333 6.66667 8.33333C4.82833 8.33333 3.33333 6.83833 3.33333 5C3.33333 3.16167 4.82833 1.66667 6.66667 1.66667ZM8.325 12.6758C8.26 13.1317 7.83917 13.45 7.3825 13.3842C7.14583 13.35 6.90417 13.3333 6.66667 13.3333C3.90917 13.3333 1.66667 15.5758 1.66667 18.3333V19.1667C1.66667 19.6267 1.29333 20 0.833333 20C0.373333 20 0 19.6267 0 19.1667V18.3333C0 14.6575 2.99083 11.6667 6.66667 11.6667C6.9825 11.6667 7.3025 11.6892 7.6175 11.7342C8.07333 11.7992 8.39 12.2208 8.32583 12.6767L8.325 12.6758Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_726">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
