const routes = {
  home: "/",
  login: "/login",
  register: "/register",
  forgot: "/forgot-password",
  reset: "/reset-password",
  events: "/events",
  createEvent: "/events/create",
  eventPreview: "/events/preview",
  selectCollaborators: "/events/select-collaborators",
  database: "/database",
  clients: "/clients",
  casting: "/casting",
  jobAds: "/job-ads",
  createCasting: "/casting/create",
  planJob: "/plan-job",
  calendar: "/calendar",
  payments: "/payments",
  invoices: "/invoices",
  createInvoice: "/invoices/create",
  reports: "/reports",
  settings: "/settings",
  profile: "/profile",
  admins: "/admins",
  collaborators: "/collaborators",
  logout: "/logout",
  webCasting: "/web-casting/:code",
  privacy: "/privacy",
  contract: "/contract",
  estimates: "/estimates",
  test: "/test",
};
export default routes;
