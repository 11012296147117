import React, { FC, useState, useEffect, useContext } from "react";
import Input from "../../components/ui/input";
import Password from "src/components/ui/password";
import Button from "src/components/ui/button";
import routes from "src/config/routes";
import { useNavigate, Navigate } from "react-router-dom";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "../../hooks/use-auth";
import {
  getErrorMessage,
  getPermittedFeatures,
  isValidEmail,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { UserType } from "src/api/types";
import L from "i18n-react";

const SignInPage: FC = () => {
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
      password: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\_])(?=.{8,})/,
          String(L.translate("ValidationErrors.password_condition"))
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const curPassword = watch("password");
  const curEmail = watch("email");

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const { authorize, setCurrentUser } = useAuth();

  const { mutate: login, isLoading } = useMutation(client.users.login, {
    onSuccess: (data) => {
      console.log(data);

      authorize(data.token!, data.refresh!);
      setCurrentUser(data.user, curPassword);

      setTimeout(() => {
        if (data.user.user_type == UserType.SUPER_ADMIN) {
          navigate(routes.events);
        } else if (data.user.user_type == UserType.COLLAB) {
          navigate(routes.profile);
        } else {
          navigate(getPermittedFeatures(data.user.permissions ?? "")[0].link);
        }
      }, 300);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.message === "invalid_credentials"
          ) {
            setAlertText(String(L.translate("Errors.wrong_password")));
          } else if (
            error.response.data &&
            error.response.data.message === "user_not_active"
          ) {
            setAlertText(String(L.translate("Errors.user_not_activated")));
          } else if (
            error.response.data &&
            error.response.data.message === "user_deleted"
          ) {
            setAlertText(String(L.translate("Errors.user_deleted")));
          } else if (
            error.response.data &&
            error.response.data.message === "email_not_registered"
          ) {
            setAlertText(String(L.translate("Errors.email_not_registered")));
          } else {
            setAlertText(
              getErrorMessage(
                error.response.data.message ??
                  String(L.translate("GlobalErrors.APISomethingWrong"))
              )
            );
          }
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.events} />;

  const onSubmit = (data: FormData) => {
    login({ ...data, ...geoPosition });
  };

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <img
              alt="staff"
              src={"/logo512.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-gilroy text-primary text-center sm:text-left">
              {String(L.translate("AuthForm.login_title"))}
            </p>
            <p className="text-[18px] font-light font-gilroy text-secondary text-center sm:text-left">
              {String(L.translate("AuthForm.login_descr"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label={String(L.translate("CommonForm.email"))}
              className="w-full"
              {...register("email")}
              error={errors.email && (errors.email.message as string)}
            />
            <Password
              label={String(L.translate("CommonForm.password"))}
              className="w-full"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
            <a
              onClick={() => navigate(routes.forgot)}
              className="text-[16px] font-medium font-gilroy text-primary underline hover:text-active cursor-pointer"
            >
              {`${String(L.translate("AuthForm.forgot_pass"))}?`}
            </a>
          </div>

          <div className="flex flex-col gap-8 mt-10">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-gilroy text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(L.translate("AuthForm.login"))}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignInPage;
