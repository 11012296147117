import { UserType } from "src/api/types";
import routes from "../config/routes";
import { DatabaseIcon } from "src/components/icons/database-icon";
import { CalendarStarIcon } from "src/components/icons/calendar-star-icon";
import { AssignIcon } from "src/components/icons/assign-icon";
import { ListCheckIcon } from "src/components/icons/list-check-icon";
import { CalendarIcon } from "src/components/icons/calendar";
import { WalletIcon } from "src/components/icons/wallet-icon";
import { ReceiptIcon } from "src/components/icons/receipt-icon";
import { DocumentIcon } from "src/components/icons/document-icon";
import { UserIcon } from "src/components/icons/user-icon";
import { UserLockIcon } from "src/components/icons/user-lock-icon";
import { AddressBookIcon } from "src/components/icons/address-book-icon";
import L from "i18n-react";
import { getPermittedFeatures } from "src/lib/utils";
import { WhatsAppIcon } from "src/components/icons/whatsapp-icon";

export const getPermissions = (userType: string, permissons: string) => {
  if (userType == UserType.SUPER_ADMIN) {
    return [
      {
        link: routes.clients,
        icon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <UserIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.clients")),
      },
      {
        link: routes.collaborators,
        icon: <AddressBookIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: (
          <AddressBookIcon className="h-[20px] w-[20px] text-white" />
        ),
        title: String(L.translate("Sidebar.collaborators")),
      },
      {
        link: routes.events,
        icon: <CalendarStarIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: (
          <CalendarStarIcon className="h-[20px] w-[20px] text-white" />
        ),
        title: String(L.translate("Sidebar.events")),
      },
      {
        link: routes.jobAds,
        icon: <WhatsAppIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <WhatsAppIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.job_ads")),
      },
      {
        link: routes.planJob,
        icon: <ListCheckIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <ListCheckIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.plan_job")),
      },

      {
        link: routes.calendar,
        icon: <CalendarIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <CalendarIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.calendar")),
      },
      {
        link: routes.casting,
        icon: <AssignIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <AssignIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.casting")),
      },
      {
        link: routes.payments,
        icon: <WalletIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <WalletIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.payments")),
      },
      {
        link: routes.invoices,
        icon: <ReceiptIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <ReceiptIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.invoices")),
      },
      {
        link: routes.reports,
        icon: <DocumentIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <DocumentIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.report")),
      },
      {
        link: routes.admins,
        icon: <UserLockIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <UserLockIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.admins")),
      },
      {
        link: routes.profile,
        icon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <UserIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.profile")),
      },
    ];
  } else if (userType == UserType.BASIC_ADMIN) {
    return getPermittedFeatures(permissons);
  } else {
    return [
      {
        link: routes.database,
        icon: <DatabaseIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <DatabaseIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.database")),
      },
      {
        link: routes.events,
        icon: <CalendarStarIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: (
          <CalendarStarIcon className="h-[20px] w-[20px] text-white" />
        ),
        title: String(L.translate("Sidebar.jobs")),
      },
      {
        link: routes.profile,
        icon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
        activeIcon: <UserIcon className="h-[20px] w-[20px] text-white" />,
        title: String(L.translate("Sidebar.profile")),
      },
    ];
  }
};
