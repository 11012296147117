import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import { useMutation } from "react-query";
import client from "../../api";
import { Client } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { Spin } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropdownWithSearchWithoutLabel from "src/components/ui/dropdown-search-without-label";
import InputWithoutLabel from "src/components/ui/input-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import Button from "src/components/ui/button";

const CreateInvoicePage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);
  const [clients, setClients] = useState<Client[]>([]);

  const schema = yup
    .object({
      date: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      due_date: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      client_id: yup
        .number()
        .positive()
        .required(String(L.translate("ValidationErrors.field_required"))),
      amount: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { mutate: getClients, isLoading: isLoadingClients } = useMutation(
    client.clients.all,
    {
      onSuccess: (data) => {
        setClients(data.results);
      },
    }
  );
  useEffect(() => {
    if (!isMounted) return;

    getClients();
  }, [isMounted]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onBack = () => {
    navigate(-1);
  };

  const { mutate: createNew, isLoading } = useMutation(client.invoices.create, {
    onSuccess: (data) => {
      navigate(-1);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_create_invoice")));
      }
      setIsAlertOpened(true);
    },
  });

  const onCreateInvoice = (data: FormData) => {
    createNew(data);
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>
        <div className="text-primary text-[24px] font-gilroy-semibold mt-4">
          {L.translate("Sidebar.new_invoice")}
        </div>
        <div className="mt-4 flex w-full md:w-[70%] lg:w-[50%] flex-col items-start justify-between gap-4">
          <Controller
            name="client_id"
            control={control}
            render={({ field }) => (
              <DropdownWithSearchWithoutLabel
                placeholder={String(L.translate("CommonForm.client_name"))}
                searchPlaceholder={String(L.translate("Base.type_client_name"))}
                className="w-full z-50"
                data={clients}
                error={errors.client_id && (errors.client_id.message as string)}
                {...field}
              />
            )}
          />
          <InputWithoutLabel
            placeholder={`€.${L.translate("CommonForm.amount")}`}
            className="w-full"
            {...register("amount")}
            error={errors.amount && (errors.amount.message as string)}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[70%] lg:w-[50%] flex-col md:flex-row items-start justify-between gap-4">
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TailwindDatePicker
                placeholder={String(L.translate("CommonForm.date"))}
                className="w-full z-40"
                error={errors.date && (errors.date.message as string)}
                {...field}
              />
            )}
          />
          <Controller
            name="due_date"
            control={control}
            render={({ field }) => (
              <TailwindDatePicker
                placeholder={String(L.translate("CommonForm.due_date"))}
                className="w-full z-30"
                error={errors.due_date && (errors.due_date.message as string)}
                {...field}
              />
            )}
          />
        </div>
        <Button
          variant="validate"
          type="submit"
          className="mt-8 rounded-[10px] uppercase font-gilroy text-[14px] font-bold leading-[24px] h-[44px]"
          onClick={handleSubmit(onCreateInvoice)}
          isLoading={isLoading}
          disabled={!isValid}
        >
          {L.translate("CommonForm.create_invoice")}
        </Button>
      </div>
    </div>
  );
};

export default CreateInvoicePage;
