export const WalletIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_771)">
        <path
          d="M17.5 5.00002H4.16667C3.45083 5.00002 2.77333 4.69002 2.30417 4.16752C2.7625 3.65585 3.42833 3.33335 4.16667 3.33335H19.1667C19.6275 3.33335 20 2.96002 20 2.50002C20 2.04002 19.6275 1.66669 19.1667 1.66669H4.16667C1.86583 1.66669 0 3.53252 0 5.83335V14.1667C0 16.4675 1.86583 18.3334 4.16667 18.3334H17.5C18.8808 18.3334 20 17.2142 20 15.8334V7.50002C20 6.11919 18.8808 5.00002 17.5 5.00002ZM18.3333 15.8334C18.3333 16.2925 17.96 16.6667 17.5 16.6667H4.16667C2.78833 16.6667 1.66667 15.545 1.66667 14.1667V5.83169C2.37833 6.36419 3.25333 6.66669 4.16667 6.66669H17.5C17.96 6.66669 18.3333 7.04085 18.3333 7.50002V15.8334ZM16.6667 11.6667C16.6667 12.1267 16.2933 12.5 15.8333 12.5C15.3733 12.5 15 12.1267 15 11.6667C15 11.2067 15.3733 10.8334 15.8333 10.8334C16.2933 10.8334 16.6667 11.2067 16.6667 11.6667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_771">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
