import { FC } from "react";
import AdminProfilePage from "./admin";
import CollaboratorProfilePage from "./collaborator";
import useAuth from "src/hooks/use-auth";
import { UserType } from "src/api/types";

const ProfilePage: FC = () => {
  const { getCurrentUser } = useAuth();

  const userType = getCurrentUser().user_type ?? "";
  return userType == UserType.COLLAB ? (
    <CollaboratorProfilePage />
  ) : (
    <AdminProfilePage />
  );
};

export default ProfilePage;
