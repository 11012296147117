import { createContext } from "react";

interface IGlobalContext {
  isAlertOpened: boolean;
  alertTitle: string;
  alertText: string;
  language: string;
  geoPosition: { lat: number; lng: number };
  collaboratorProfileFilled: boolean;
  setLanguage: (text: string) => void;
  setIsAlertOpened: (opened: boolean) => void;
  setAlertText: (text: string) => void;
  setAlertTitle: (text: string) => void;
  setGeoPosition: ({ lat, lng }: { lat: number; lng: number }) => void;
  setCollaboratorProfileFilled: (filled: boolean) => void;
}

const defaultState = {
  isAlertOpened: false,
  alertText: "",
  alertTitle: "",
  language: "EN",
  geoPosition: { lat: 0, lng: 0 },
  collaboratorProfileFilled: false,
  setLanguage: (text: string) => {},
  setIsAlertOpened: (opened: boolean) => {},
  setAlertText: (text: string) => {},
  setAlertTitle: (text: string) => {},
  setGeoPosition: ({ lat, lng }: { lat: number; lng: number }) => {},
  setCollaboratorProfileFilled: (filled: boolean) => {},
};

const GlobalContext = createContext<IGlobalContext>(defaultState);
export default GlobalContext;
