import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/datatable.css";
import "./assets/css/scrollbar.css";
import "./assets/css/other.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-medium-image-zoom/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import App from "./pages";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
