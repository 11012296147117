import { FC, useContext, useEffect, useState, useRef } from "react";
import GlobalContext from "src/context/global-context";
import { useNavigate, Navigate } from "react-router-dom";
import Button from "../../components/ui/button";
import routes from "../../config/routes";
import useAuth from "../../hooks/use-auth";

const WelcomePage: FC = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuth();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [textSize, setTextSize] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
    containerRef.current &&
      setContainerHeight(
        containerRef.current ? containerRef.current.offsetHeight : 0
      );
    containerRef.current &&
      setContainerWidth(
        containerRef.current ? containerRef.current.offsetWidth : 0
      );
  }

  useEffect(() => {
    containerRef.current &&
      setContainerHeight(
        containerRef.current ? containerRef.current.offsetHeight : 0
      );
    containerRef.current &&
      setContainerWidth(
        containerRef.current ? containerRef.current.offsetWidth : 0
      );
  }, [containerRef.current]);

  useEffect(() => {
    const calcTextSize = Math.round(containerHeight / 10);

    if (isMobile) {
      setTextSize(36);
    } else {
      if (calcTextSize < 30) {
        setTextSize(30);
      } else {
        if (containerWidth < 720) {
          setTextSize(Math.round(containerWidth / 10));
        } else {
          if (calcTextSize > 80) {
            setTextSize(72);
          } else {
            setTextSize(calcTextSize);
          }
        }
      }
    }
  }, [containerHeight, containerWidth]);

  if (isAuthorized) return <Navigate to={routes.events} />;

  return (
    <div
      className={`${
        !isMobile ? "bg-hero-main-no-logo" : "bg-hero-main-no-logo"
      } relative flex w-full h-full bg-cover bg-no-repeat bg-center`}
    ></div>
  );
};

export default WelcomePage;
