import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  EyeColors,
  EyeColors_IT,
  HairColors,
  HairColors_IT,
  Langauges,
  Langauges_IT,
  LIST_PAGE_SIZE,
  Provinces,
  ShoeSizes,
} from "src/lib/constants";
import L from "i18n-react";
import { CollabDataType } from "src/lib/data-types";
import { Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { CollabSearchQueryOptions, SearchQueryOptions } from "src/api/types";
import { SortIcon } from "src/components/icons/sort-icon";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  calculateDistance,
  capitalizeFirstLetter,
  getErrorMessage,
} from "src/lib/utils";
import { makeColumnHeader } from "../table-columns/make-column-header";
import moment from "moment";
import CitySelection from "../ui/city-selection";
import InputWithoutLabel from "../ui/input-without-label";
import Dropdown from "../ui/dropdown";
import { CrossIcon } from "../icons/cross-icon";
import { StarFillIcon } from "../icons/star-fill-icon";
import InputWithRange from "../ui/input-range";
import DropdownWithoutLabel from "../ui/dropdown-without-label";
import DropdownWithSearchWithoutLabel from "../ui/dropdown-search-without-label";

const CollabFilterSection = ({
  isMore,
  dataSource,
  setFilteredDataSource,
  setSelectedLocation,
}: {
  isMore: boolean;
  dataSource: CollabDataType[];
  setFilteredDataSource: any;
  setSelectedLocation?: any;
}) => {
  const { language } = useContext(GlobalContext);

  const [selectedCity, setSelectedCity] = useState<
    | {
        lat: number;
        lng: number;
      }
    | undefined
  >(undefined);
  const [selectedName, setSelectedName] = useState("");
  const [selectedHeight, setSelectedHeight] = useState({ from: -1, to: -1 });
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedLang, setSelectedLang] = useState(-1);
  const [selectedProvince, setSelectedProvince] = useState(-1);
  const [selectedSize, setSelectedSize] = useState(-1);
  const [selectedHair, setSelectedHair] = useState(-1);
  const [selectedShoes, setSelectedShoes] = useState("");
  const [selectedEye, setSelectedEye] = useState(-1);
  const [selectedAvailableProvinces, setSelectedAvailableProvinces] =
    useState(-1);
  const [selectedHaccp, setSelectedHaccp] = useState(-1);
  const [selectedTattoos, setSelectedTattoos] = useState(-1);
  const [selectedOwnCar, setSelectedOwnCar] = useState(-1);
  const [selectedPiercings, setSelectedPiercings] = useState(-1);

  useEffect(() => {
    setSelectedLocation && setSelectedLocation(null);

    let tempResult = [...dataSource];

    if (selectedName.trim().length > 0) {
      tempResult = [...dataSource].filter((item) =>
        `${item.first_name ?? ""} ${item.last_name ?? ""}`
          .toLowerCase()
          .includes(selectedName.trim().toLowerCase())
      );
    }

    if (selectedCity) {
      tempResult = [...dataSource].filter(
        (item) =>
          calculateDistance(
            selectedCity!.lat,
            selectedCity!.lng,
            item.lat,
            item.lng
          ) < 30
      );
    }

    if (selectedLang > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;
        console.log(item.info, selectedLang);

        if ("italian" in item.info && selectedLang == 1) return true;
        if ("english" in item.info && selectedLang == 2) return true;
        if ("french" in item.info && selectedLang == 3) return true;
        if ("german" in item.info && selectedLang == 4) return true;
        if ("spanish" in item.info && selectedLang == 5) return true;

        return false;
      });
    }

    if (selectedAge.length > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        const birthDate = moment(item.info.date_of_birth).format("yyyy");
        const age = `${moment().diff(birthDate, "years")}`;
        if (age == selectedAge) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedHeight.from != -1 || selectedHeight.to != -1) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (selectedHeight.from == -1) {
          if (item.info.height <= selectedHeight.to) {
            return true;
          }
        } else if (selectedHeight.to == -1) {
          if (item.info.height >= selectedHeight.from) {
            return true;
          }
        } else {
          if (
            item.info.height >= selectedHeight.from &&
            item.info.height <= selectedHeight.to
          ) {
            return true;
          }
        }

        return false;
      });
    }

    if (selectedHaccp > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (item.info.have_HACCP_certificate == selectedHaccp - 1) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedOwnCar > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (item.info.have_car == selectedOwnCar - 1) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (selectedTattoos > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;
        if (!("have_tatoo" in item.info)) return false;

        if (item.info.have_tatoo == selectedTattoos - 1) {
          return true;
        } else {
          return false;
        }
      });
    }
    if (selectedPiercings > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;
        if (!("have_piercing" in item.info)) return false;

        if (item.info.have_piercing == selectedPiercings - 1) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedEye > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;
        if (!("eye_color" in item.info)) return false;

        if (item.info.eye_color == selectedEye) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedHair > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (item.info.hair_color == selectedHair) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedSize > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (item.info.italian_size == selectedSize) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedShoes.length > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (item.info.shoe_size == selectedShoes) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedAvailableProvinces > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.info) return false;

        if (
          item.info.provinces.includes(
            Provinces[selectedAvailableProvinces - 1]
          )
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedProvince > 0) {
      tempResult = [...dataSource].filter((item) => {
        if (!item.province) return false;

        if (
          item.province.toLowerCase() ==
          Provinces[selectedProvince - 1].toLowerCase()
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    setSelectedLocation &&
      tempResult.length > 0 &&
      setSelectedLocation({
        lat: tempResult[0].lat,
        lng: tempResult[0].lng,
      });

    setTimeout(() => setSelectedLocation && setSelectedLocation(null), 600);

    setFilteredDataSource(tempResult);
  }, [
    dataSource,
    selectedName,
    selectedCity,
    selectedHeight,
    selectedAge,
    selectedLang,
    selectedPiercings,
    selectedOwnCar,
    selectedTattoos,
    selectedHaccp,
    selectedAvailableProvinces,
    selectedEye,
    selectedShoes,
    selectedHair,
    selectedSize,
    selectedProvince,
  ]);

  return (
    <div
      className={`z-20 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 ${
        isMore && "3xl:grid-cols-5"
      } gap-4 mb-4`}
    >
      <InputWithoutLabel
        inputClassName="bg-white shadow-card"
        label={String(L.translate("CommonForm.name"))}
        className="w-full"
        showClose={selectedName.length > 0}
        clickedClose={() => setSelectedName("")}
        value={selectedName}
        onChange={(e) => setSelectedName(e.target.value.trim())}
      />
      <CitySelection
        required
        label={String(L.translate("FilterOptions.city"))}
        onPicked={(location?: { lat: number; lng: number }) =>
          setSelectedCity(location)
        }
      />
      <InputWithRange
        label={String(L.translate("FilterOptions.height"))}
        inputClassName="bg-white shadow-card"
        type="number"
        currentValue={selectedHeight}
        onChange={(e: { from: number; to: number }) => setSelectedHeight(e)}
      />
      {isMore && (
        <>
          <InputWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.age"))}
            type="number"
            className="w-full"
            showClose={selectedAge.length > 0}
            clickedClose={() => setSelectedAge("")}
            value={selectedAge}
            onChange={(e) => setSelectedAge(e.target.value.trim())}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.languages"))}
            className="w-full"
            value={selectedLang}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...(language == "EN" ? Langauges : Langauges_IT),
            ]}
            onChange={(e: number) => setSelectedLang(e)}
          />
          <DropdownWithSearchWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.province"))}
            className="w-full"
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...Provinces.map((item, index) => ({
                id: index + 1,
                name: item,
              })),
            ]}
            value={selectedProvince}
            onChange={(e: number) => setSelectedProvince(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.hair"))}
            className="w-full"
            value={selectedHair}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...(language == "EN" ? HairColors : HairColors_IT),
            ]}
            onChange={(e: number) => setSelectedHair(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.size"))}
            className="w-full"
            value={selectedSize}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...ShoeSizes,
            ]}
            onChange={(e: number) => setSelectedSize(e)}
          />
          <InputWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.shoes"))}
            type="number"
            className="w-full"
            showClose={selectedShoes.length > 0}
            clickedClose={() => setSelectedShoes("")}
            value={selectedShoes}
            onChange={(e) => setSelectedShoes(e.target.value.trim())}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.eyes"))}
            className="w-full"
            value={selectedEye}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...(language == "EN" ? EyeColors : EyeColors_IT),
            ]}
            onChange={(e: number) => setSelectedEye(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.haccp"))}
            className="w-full"
            value={selectedHaccp}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              { id: 1, name: String(L.translate("Base.yes")) },
              { id: 2, name: String(L.translate("Base.no")) },
            ]}
            onChange={(e: number) => setSelectedHaccp(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.own_car"))}
            className="w-full"
            value={selectedOwnCar}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              { id: 1, name: String(L.translate("Base.yes")) },
              { id: 2, name: String(L.translate("Base.no")) },
            ]}
            onChange={(e: number) => setSelectedOwnCar(e)}
          />
          <DropdownWithSearchWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.avaialble_in_provinces"))}
            className="w-full"
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              ...Provinces.map((item, index) => ({
                id: index + 1,
                name: item,
              })),
            ]}
            value={selectedAvailableProvinces}
            onChange={(e: number) => setSelectedAvailableProvinces(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.tattoos"))}
            className="w-full"
            value={selectedTattoos}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              { id: 1, name: String(L.translate("Base.yes")) },
              { id: 2, name: String(L.translate("Base.no")) },
            ]}
            onChange={(e: number) => setSelectedTattoos(e)}
          />
          <DropdownWithoutLabel
            inputClassName="bg-white shadow-card"
            label={String(L.translate("FilterOptions.piercings"))}
            className="w-full"
            value={selectedPiercings}
            data={[
              { id: -1, name: String(L.translate("Base.all")) },
              { id: 1, name: String(L.translate("Base.yes")) },
              { id: 2, name: String(L.translate("Base.no")) },
            ]}
            onChange={(e: number) => setSelectedPiercings(e)}
          />
        </>
      )}
    </div>
  );
};

export default CollabFilterSection;
