import React, { FC, useState, useEffect, useContext } from "react";
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import { useNavigate, Navigate } from "react-router-dom";
import { fadeInOut } from "../../lib/framer-motion/fade-in-out";
import { motion } from "framer-motion";
import routes from "../../config/routes";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "../../hooks/use-auth";
import { getErrorMessage, getPermittedFeatures } from "../../lib/utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useModalAction } from "../../components/modal-views/context";
import GlobalContext from "src/context/global-context";
import L from "i18n-react";

const ForgotPasswordPage: FC = () => {
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { setIsAlertOpened, setAlertTitle, setAlertText } =
    useContext(GlobalContext);

  const { mutate: forgotPassword, isLoading } = useMutation(
    client.users.forgotPassword,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.forgot_password");
        setIsAlertOpened(true);

        setTimeout(() => navigate(routes.login), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.message === "email_not_found"
            ) {
              setAlertText(String(L.translate("Errors.email_not_registered")));
            } else {
              setAlertText(
                String(L.translate("Errors.failed_forgot_password"))
              );
            }
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { isAuthorized, getCurrentUser, unauthorize } = useAuth();

  if (isAuthorized) {
    const permissions = getCurrentUser().permissions ?? "";

    if (getPermittedFeatures(permissions).length > 0) {
      return <Navigate to={getPermittedFeatures(permissions)[0].link} />;
    } else {
      unauthorize();
    }
  }

  const onSubmit = (data: FormData) => {
    forgotPassword(data);
  };

  return (
    <motion.div
      variants={fadeInOut()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <Button
              variant="icon"
              onClick={() => navigate(-1)}
              className="bg-gray-modern-400 rounded-[8px] px-4 py-2 w-[120px] mb-10 hover:bg-light-600"
            >
              <ArrowLeftIcon className="w-[20px] h-[20px] text-white" />
              <p className="font-gilroy text-[18px] font-medium text-white">
                {String(L.translate("Base.back"))}
              </p>
            </Button>
            <img
              src={"/logo512.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-gilroy text-primary text-center sm:text-left">
              {String(L.translate("AuthForm.forgot_pass"))}
            </p>
            <p className="text-[18px] font-light font-gilroy text-secondary text-center sm:text-left">
              {String(L.translate("AuthForm.forgot_descr"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label={String(L.translate("CommonForm.email"))}
              className="w-full"
              {...register("email")}
              error={errors.email && (errors.email.message as string)}
            />
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Button
              type="submit"
              variant="validate"
              className="rounded-[10px] font-gilroy text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(L.translate("Base.submit"))}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default ForgotPasswordPage;
