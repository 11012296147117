import { FC, useRef, useState, useEffect, useContext } from "react";
import { Event, UserType } from "src/api/types";
import {
  capitalizeFirstLetter,
  getContractStatusTextColor,
  getEventDurationString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { ConfigValue } from "src/config";
import { UserIcon } from "../icons/user-icon";
import useAuth from "src/hooks/use-auth";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { AdvancedMarker, Map, APIProvider } from "@vis.gl/react-google-maps";
import Button from "../ui/button";
import { CalendarIcon } from "../icons/calendar";
import { useModalAction } from "../modal-views/context";
import moment from "moment";

const EventDetailsView = ({
  event,
  isCenter = false,
  showMap = false,
  isEditDates = false,
  onEdit,
}: {
  event: Event;
  isCenter?: boolean;
  showMap?: boolean;
  isEditDates?: boolean;
  onEdit?: any;
}) => {
  const { getCurrentUser } = useAuth();
  const { language } = useContext(GlobalContext);
  const { openModal } = useModalAction();

  const userType = getCurrentUser().user_type ?? "";

  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  useEffect(() => {
    if (event.coordinate) {
      const positions = event.coordinate!.split(",");
      positions.length >= 2
        ? setSelectedLocation({
            lat: Number(positions[0]),
            lng: Number(positions[1]),
          })
        : setSelectedLocation(null);
    } else {
      setSelectedLocation(null);
    }
  }, [event.location]);

  const onEditNumbers = () => {
    onEdit && onEdit();
  };

  // md:w-[80%] lg:w-[70%]
  const makeNumbersPerDayUI = () => {
    if (event.numbers_per_day) {
      const items = event.numbers_per_day.split(",");
      if (items.length > 0) {
        const daysInfo = items.map((item) => {
          const infos = item.split(":");
          return { date: infos[0], amount: infos[1] };
        });

        return (
          <div className="flex flex-col items-start justify-center">
            <label className="font-gilroy text-[12px] text-secondary">
              {L.translate("CommonForm.number_of_collaborators_per_day")}
            </label>
            <div className="mt-2 flex flex-wrap w-full flex-col sm:flex-row items-start justify-start gap-x-4 gap-y-2">
              {daysInfo.map((eachDay) => (
                <div
                  key={`collab-number-per-${eachDay.date}`}
                  className="flex flex-row gap-2"
                >
                  <p className="font-gilroy-semibold text-[14px] text-primary">
                    {`${moment(eachDay.date).format("DD/MM/yyyy")}: `}
                  </p>
                  <p className="font-gilroy-bold text-[14px] text-active">
                    {eachDay.amount}
                  </p>
                </div>
              ))}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="mt-4 flex w-full flex-col items-start justify-start gap-4">
      <div className="flex flex-wrap w-full flex-col sm:flex-row items-start justify-start gap-4 sm:gap-8">
        {(userType == UserType.BASIC_ADMIN ||
          userType == UserType.SUPER_ADMIN) && (
          <>
            <div className="flex flex-col items-start justify-center">
              <label className="font-gilroy text-[12px] text-secondary">
                {L.translate("CommonForm.event_id")}
              </label>
              <label className="font-gilroy-semibold text-[14px] text-active">
                {event.id.toString().padStart(6, "0")}
              </label>
            </div>
            <div className="flex flex-col items-start justify-center">
              <label className="font-gilroy text-[12px] text-secondary">
                {L.translate("CommonForm.client")}
              </label>
              <label className="font-gilroy-semibold text-[14px] text-active">
                {event.client.name}
              </label>
            </div>
          </>
        )}
        <div className="flex flex-col items-start justify-center">
          <label className="font-gilroy text-[12px] text-secondary">
            {L.translate("CommonForm.event_name")}
          </label>
          <label className="font-gilroy-semibold text-[14px] text-active">
            {capitalizeFirstLetter(event.name)}
          </label>
        </div>
        {userType == UserType.SUPER_ADMIN && (
          <div className="flex flex-col items-start justify-center">
            <label className="font-gilroy text-[12px] text-secondary">
              {L.translate("CommonForm.invoice_no")}
            </label>
            <label className="font-gilroy-semibold text-[14px] text-active">
              {event.invoice_no.length == 0 ? "-" : event.invoice_no}
            </label>
          </div>
        )}
        <div className="flex flex-col items-start justify-center">
          <label className="font-gilroy text-[12px] text-secondary">
            {L.translate("CommonForm.event_date")}
          </label>
          <label className="font-gilroy-semibold text-[14px] text-active">
            {getEventDurationString(
              language,
              event.start_date,
              event.end_date,
              event.dates
            )}
          </label>
        </div>
        {userType == UserType.SUPER_ADMIN && (
          <div className="flex flex-col items-start justify-center">
            <label className="font-gilroy text-[12px] text-secondary">
              {`${L.translate("CommonForm.total_cost")} (€)`}
            </label>
            <label className="font-gilroy-semibold text-[14px] text-active">
              {event.contract
                ? makeNumberWithFloatingDigits(event.contract, 2)
                : "-"}
            </label>
          </div>
        )}
        <div className="flex flex-col items-start justify-center">
          <label className="font-gilroy text-[12px] text-secondary">
            {L.translate("CommonForm.location")}
          </label>
          <label className="font-gilroy-semibold text-[14px] text-active">
            {event.location}
          </label>
        </div>
        {(userType == UserType.BASIC_ADMIN ||
          userType == UserType.SUPER_ADMIN) && (
          <div className="flex flex-col items-start justify-center">
            <label className="font-gilroy text-[12px] text-secondary">
              {L.translate("CommonForm.contract_received")}
            </label>
            <div className="flex flex-row gap-2 items-center">
              <UserIcon
                className={`${getContractStatusTextColor(
                  `${event.contract_received}/${event.number_of_collaborators}`
                )} w-[16px] h-[16px]`}
              />
              <p
                className={`${getContractStatusTextColor(
                  `${event.contract_received}/${event.number_of_collaborators}`
                )} font-gilroy-semibold text-[14px]`}
              >
                {`${event.contract_received}/${event.number_of_collaborators}`}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-center">
        <label className="font-gilroy text-[12px] text-secondary">
          {L.translate("CommonForm.description")}
        </label>
        <label className="font-gilroy-semibold text-[14px] text-active">
          {event.description}
        </label>
      </div>
      {(userType == UserType.SUPER_ADMIN || userType == UserType.BASIC_ADMIN) &&
        makeNumbersPerDayUI()}
      {(userType == UserType.SUPER_ADMIN || userType == UserType.BASIC_ADMIN) &&
        event.start_date && (
          <Button
            variant="text"
            className="text-[14px] font-gilroy px-4 !text-white py-2 rounded-[8px] hover:bg-pink-700 bg-purple"
            onClick={onEditNumbers}
          >
            <CalendarIcon className="text-white w-4 h-4" />
            {L.translate("CommonForm.edit_dates")}
          </Button>
        )}
      {event.bid_notes && event.bid_notes.length > 0 && (
        <div className="flex flex-col items-start justify-center">
          <label className="font-gilroy text-[12px] text-secondary">
            {L.translate("CommonForm.notes")}
          </label>
          <label className="font-gilroy-semibold text-[14px] text-active">
            {event.bid_notes}
          </label>
        </div>
      )}
      {showMap && selectedLocation && (
        <APIProvider apiKey={ConfigValue.GOOGLE_MAPS_API}>
          <Map
            mapId={ConfigValue.GOOGLE_MAP_ID}
            defaultCenter={{
              lat: selectedLocation?.lat,
              lng: selectedLocation?.lng,
            }}
            defaultZoom={13}
            gestureHandling={"greedy"}
            style={{
              width: "100%",
              backgroundColor: "#f2f4f7",
              height: "360px",
              borderRadius: 8,
            }}
            disableDefaultUI
          >
            <AdvancedMarker
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
            >
              <div className="flex items-center justify-center bg-active w-[64px] h-[64px] rounded-full">
                <img
                  src={isMobile ? "/logo192.png" : "/logo512.png"}
                  className="w-[48px] h-[48px] object-cover rounded-full"
                />
              </div>
            </AdvancedMarker>
          </Map>
        </APIProvider>
      )}
    </div>
  );
};

export default EventDetailsView;
