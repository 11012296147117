import { ILanguages } from "./types";

export interface Ilangs {
  EN: string;
  IT: string;
}

export const lgNames: ILanguages = {
  EN: "English",
  IT: "Italian",
};
