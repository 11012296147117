export const CreditCardIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_827)">
        <path
          d="M4.58331 6.24152C5.27367 6.24152 5.83331 6.80116 5.83331 7.49152C5.83331 8.18187 5.27367 8.74152 4.58331 8.74152C3.89296 8.74152 3.33331 8.18187 3.33331 7.49152C3.33331 6.80116 3.89296 6.24152 4.58331 6.24152Z"
          fill="currentColor"
        />
        <path
          d="M15.8333 17.9082H4.16667C3.062 17.9069 2.00296 17.4675 1.22185 16.6864C0.440735 15.9052 0.00132321 14.8462 0 13.7415L0 7.07487C0.00132321 5.97021 0.440735 4.91117 1.22185 4.13005C2.00296 3.34894 3.062 2.90953 4.16667 2.9082H15.8333C16.938 2.90953 17.997 3.34894 18.7782 4.13005C19.5593 4.91117 19.9987 5.97021 20 7.07487V13.7415C19.9987 14.8462 19.5593 15.9052 18.7782 16.6864C17.997 17.4675 16.938 17.9069 15.8333 17.9082ZM4.16667 16.2415H15.8333C16.4964 16.2415 17.1323 15.9781 17.6011 15.5093C18.0699 15.0405 18.3333 14.4046 18.3333 13.7415H1.66667C1.66667 14.4046 1.93006 15.0405 2.3989 15.5093C2.86774 15.9781 3.50363 16.2415 4.16667 16.2415ZM15.8333 4.57487H4.16667C3.50363 4.57487 2.86774 4.83826 2.3989 5.3071C1.93006 5.77594 1.66667 6.41183 1.66667 7.07487V12.0749H18.3333V7.07487C18.3333 6.41183 18.0699 5.77594 17.6011 5.3071C17.1323 4.83826 16.4964 4.57487 15.8333 4.57487Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_827">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(1 0 0 -1 0 20.4082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
