import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { type Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import { MapMarker } from "./marker";
import { Carousel } from "react-responsive-carousel";
import ImageWithLoading from "../ui/image-with-loading";
import L from "i18n-react";
import { capitalizeFirstLetter } from "src/lib/utils";
import { ShoeSizes } from "src/lib/constants";
import Button from "../ui/button";
import { useModalAction } from "../modal-views/context";
import GlobalContext from "src/context/global-context";

export type ClusteredMapMarkersProps = {
  items: any[];
  selectedID?: number;
  resetSelectedID?: any;
  onRefreshList?: any;
};

/**
 * The ClusteredTreeMarkers component is responsible for integrating the
 * markers with the markerclusterer.
 */
export const ClusteredMapMarkers = ({
  items,
  selectedID = -1,
  resetSelectedID,
  onRefreshList,
}: ClusteredMapMarkersProps) => {
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const [selectedTreeKey, setSelectedTreeKey] = useState<string | null>(null);

  const { openModal } = useModalAction();
  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);

  const selectedTree = useMemo(
    () =>
      items && selectedTreeKey
        ? items.find((t) => t.id == selectedTreeKey)!
        : null,
    [items, selectedTreeKey]
  );

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  useEffect(() => {
    if (selectedID == -1) setSelectedTreeKey(null);

    setSelectedTreeKey(`${selectedID}`);
  }, [selectedID]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const onViewProfile = () => {
    if (!selectedTree) return;
    console.log(selectedTree);

    if (selectedTree.info) {
      openModal("COLLABORATOR_BID", {
        payload: {
          userID: selectedTree.id,
          isBid: false,
          bidInfo: selectedTree.info,
          main_photo: selectedTree.main_photo,
          photo2: selectedTree.photo2,
          photo3: selectedTree.photo3,
          photo4: selectedTree.photo4,
          photo5: selectedTree.photo5,
          photo6: selectedTree.photo6,
          photo7: selectedTree.photo7,
          photo8: selectedTree.photo8,
          photo9: selectedTree.photo9,
          cv: selectedTree.cv,
          lat: selectedTree.lat,
          lng: selectedTree.lng,
        },
        refreshCallback: onRefreshList ? onRefreshList : () => {},
      });
    } else {
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.no_profile")));
      setIsAlertOpened(true);
    }
  };

  const handleInfoWindowClose = useCallback(() => {
    setSelectedTreeKey(null);
    resetSelectedID && resetSelectedID();
  }, []);

  const handleMarkerClick = useCallback((tree: any) => {
    setSelectedTreeKey(tree.id);
  }, []);
  return (
    <>
      {items.map((item) => (
        <MapMarker
          key={item.id}
          info={item}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}
      {selectedTree && selectedTreeKey && (
        <InfoWindow
          anchor={markers[selectedTreeKey]}
          onCloseClick={handleInfoWindowClose}
          headerContent={
            <label className="font-gilroy-bold text-primary text-center text-[16px]">{`${capitalizeFirstLetter(
              selectedTree.first_name
            )} ${capitalizeFirstLetter(selectedTree.last_name)}`}</label>
          }
        >
          <div className="flex flex-col gap-1">
            <label className="font-gilroy-medium text-secondary">
              {`${L.translate("CollaboratorProfile.telephone_number")}: `}
              <span className="font-gilroy-bold text-primary">
                {selectedTree.telephone_number
                  ? selectedTree.telephone_number.length > 0
                    ? selectedTree.telephone_number
                    : "-"
                  : "-"}
              </span>
            </label>
            <div className="flex flex-row items-center justify-between">
              <label className="font-gilroy-medium text-secondary">
                {`${L.translate("CollaboratorProfile.size")}: `}
                <span className="font-gilroy-bold text-primary">
                  {selectedTree.info
                    ? `${
                        ShoeSizes.filter(
                          (eachSize) =>
                            eachSize.id == selectedTree.info.italian_size
                        )[0].name
                      }`
                    : "-"}
                </span>
              </label>
              <label className="font-gilroy-medium text-secondary">
                {`${L.translate("CollaboratorProfile.height_without_cm")}: `}
                <span className="font-gilroy-bold text-primary">
                  {selectedTree.info ? `${selectedTree.info.height}cm` : "-"}
                </span>
              </label>
            </div>
            <Button
              variant="solid"
              className="rounded-[8px] text-[12px] font-gilroy leading-[24px] bg-active text-white hover:bg-main mt-1"
              onClick={onViewProfile}
            >
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("CommonForm.view_profile")}
              </span>
            </Button>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
