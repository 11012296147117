import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "src/hooks/use-auth";
import L from "i18n-react";

export default function Footer() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const location = useLocation();
  const { isAuthorized } = useAuth();

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const makeFooter = () => {
    const today = new Date();
    const year = today.getFullYear();

    return (
      <div className="flex w-full h-full px-4 py-2 bg-whole-gray shadow-card">
        <div className="flex w-full flex-col items-center justify-between py-2">
          <div className="flex w-full flex-row items-start justify-between">
            <p className="text-[12px] text-primary font-gilroy-semibold flex-shrink max-w-[55%] lg:max-w-[50%] dark:text-light-600">
              {`${L.translate("Sidebar.copyright_footer")} @${year}`}
            </p>
            <div className="flex flex-row flex-shrink-0">
              <a
                href="/privacy"
                target="_blank"
                //className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 border-r-[1px] border-primary dark:border-light-600 h-[16px]"
                className="text-[12px] font-light text-primary underline border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 h-[16px]"
                rel="noreferrer"
              >
                {String(L.translate("Sidebar.privacy"))}
              </a>
              {/*
                <>
                  <a
                    href="/cookie"
                    target="_blank"
                    className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 border-r-[1px] border-primary dark:border-light-600 h-[16px]"
                    rel="noreferrer"
                  >
                    {String(L.translate("Sidebar.cookie"))}
                  </a>
                  <a
                    href="/terms"
                    target="_blank"
                    className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 h-[16px]"
                    rel="noreferrer"
                  >
                    {String(L.translate("Sidebar.terms"))}
                  </a>
                </>
              */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const makeMobileFooter = () => {
    const today = new Date();
    const year = today.getFullYear();

    return (
      <div className="flex w-full h-full px-4 py-2">
        <div className="flex w-full flex-col items-center justify-between py-2">
          {/*
            <p className="text-[24px] w-full text-left font-semibold text-primary font-gilroy dark:text-light">
              {"Staff MS"}
            </p>
          */}
          <div className="flex w-full flex-col items-start justify-between">
            <p className="text-[12px] text-primary font-gilroy-semibold flex-shrink w-full dark:text-light-600">
              {/*`Copyright ${year} All rights reserved. WithYouAgency`*/}
              {`${L.translate("Sidebar.copyright_footer")} @${year}`}
            </p>
            <div className="flex flex-row">
              <a
                href="/privacy"
                target="_blank"
                //className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy pr-2 border-r-[1px] border-primary dark:border-light-600 h-[16px]"
                className="text-[12px] font-light underline text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy pr-2 h-[16px]"
                rel="noreferrer"
              >
                {"Privacy Policy"}
              </a>
              {/*
                <>
                  <a
                    href="/cookie"
                    target="_blank"
                    className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 border-r-[1px] border-primary dark:border-light-600 h-[16px]"
                    rel="noreferrer"
                  >
                    {"Cookie Policy"}
                  </a>
                  <a
                    href="/terms"
                    target="_blank"
                    className="text-[12px] font-light text-primary border-opacity-60 dark:text-light-600 dark:hover:text-active hover:text-secondary font-gilroy px-2 h-[16px]"
                    rel="noreferrer"
                  >
                    {"Terms & Conditions"}
                  </a>
                </>
              */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <footer
      className={`${
        !isAuthorized && "border-t border-light-300"
      } w-full dark:border-dark-300 z-20 bg-whole-gray`}
    >
      {isMobile ? makeMobileFooter() : makeFooter()}
    </footer>
  );
}
