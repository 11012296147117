import classNames from "classnames";
import L from "i18n-react";
import { forwardRef, useEffect, useState } from "react";
import InputWithoutLabel from "./input-without-label";
import moment from "moment";
type InputWithoutLabelProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  className?: string;
  dates: string;
  currentValue: string;
  value?: number;
  onChange?: any;
  callback: any;
  inputClassName?: string;
  required?: boolean;
};

const CollabNumbersPerDay = forwardRef<
  HTMLInputElement,
  InputWithoutLabelProps
>(
  (
    {
      label,
      error,
      type = "text",
      className,
      dates,
      inputClassName = "bg-gray-modern-100",
      required,
      value,
      currentValue,
      onChange,
      callback,
      ...props
    },
    ref
  ) => {
    const [dateList, setDateList] = useState(dates.split(","));
    const [numberList, setNumberList] = useState<{ [key: string]: number }>({});

    useEffect(() => {
      setDateList(dates.split(","));
      if (!currentValue) return;

      if (currentValue && currentValue.length > 0) {
        let tempList: { [key: string]: number } = {};
        const items = currentValue.split(",");
        items.map((item) => {
          const day_number = item.split(":");
          if (dates.split(",").includes(day_number[0]))
            tempList[day_number[0]] = Number(day_number[1]);
        });
        setNumberList(tempList);
      }
    }, [currentValue, dates]);

    useEffect(() => {
      const sortedByValue = Object.entries(numberList)
        .sort(([valueA], [valueB]) => {
          return valueA.localeCompare(valueB); // Ascending order
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as Record<string, number>);

      const result = Object.entries(sortedByValue).map(
        ([key, value]) => `${key}:${value}`
      );

      onChange(
        Object.keys(sortedByValue).length == dateList.length ? 1 : undefined
      );
      callback(result.join(","));
    }, [numberList]);

    const onTextChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      date: string
    ) => {
      const tempList = { ...numberList, [date]: Number(e.target.value) };
      setNumberList(tempList);
    };

    return (
      <div className={className}>
        <label className="block text-[16px] font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <div className="flex flex-wrap gap-2">
            {dateList.map((item) => (
              <div
                key={`collab-number-${item}`}
                className="flex flex-col items-start justify-center"
              >
                <label className="block text-[12px] font-gilroy font-medium text-secondary">
                  {moment(item).format("DD/MM/YYYY")}
                </label>
                <InputWithoutLabel
                  type="number"
                  value={numberList[item] ?? ""}
                  className="w-[100px]"
                  onChange={(e) => onTextChange(e, item)}
                />
              </div>
            ))}
          </div>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

export default CollabNumbersPerDay;
