export const AngleDownSmallIcon: React.FC<React.SVGAttributes<{}>> = (
  props
) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5917 6.84165C15.5142 6.76355 15.422 6.70155 15.3205 6.65924C15.2189 6.61694 15.11 6.59515 15 6.59515C14.89 6.59515 14.7811 6.61694 14.6795 6.65924C14.578 6.70155 14.4858 6.76355 14.4083 6.84165L10.5917 10.6583C10.5142 10.7364 10.422 10.7984 10.3205 10.8407C10.2189 10.883 10.11 10.9048 10 10.9048C9.89 10.9048 9.78108 10.883 9.67953 10.8407C9.57798 10.7984 9.48581 10.7364 9.40834 10.6583L5.59168 6.84165C5.51421 6.76355 5.42204 6.70155 5.32049 6.65924C5.21894 6.61694 5.11002 6.59515 5.00001 6.59515C4.89 6.59515 4.78108 6.61694 4.67953 6.65924C4.57798 6.70155 4.48581 6.76355 4.40834 6.84165C4.25313 6.99779 4.16602 7.209 4.16602 7.42915C4.16602 7.64931 4.25313 7.86052 4.40834 8.01665L8.23334 11.8417C8.70209 12.3098 9.33751 12.5728 10 12.5728C10.6625 12.5728 11.2979 12.3098 11.7667 11.8417L15.5917 8.01665C15.7469 7.86052 15.834 7.64931 15.834 7.42915C15.834 7.209 15.7469 6.99779 15.5917 6.84165Z"
        fill="currentColor"
      />
    </svg>
  );
};
