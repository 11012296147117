import { useState, useEffect, FC, ReactNode } from "react";
import GlobalContext from "./global-context";
import { ConfigValue } from "src/config";
import L from "i18n-react";
import { languages } from "../languages";

export default function GlobalContextWrapper({
  children,
}: React.PropsWithChildren<{}>) {
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [collaboratorProfileFilled, setCollaboratorProfileFilled] =
    useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [geoPosition, setGeoPosition] = useState({ lat: 0, lng: 0 });
  const [language, setLanguage] = useState(
    localStorage.getItem(ConfigValue.LANGUAGE) || "EN"
  );

  L.setTexts(languages[language]);

  const handleSetLanguage = (value: string) => {
    localStorage.setItem(ConfigValue.LANGUAGE, value);
    setLanguage(value);
  };

  return (
    <GlobalContext.Provider
      value={{
        isAlertOpened,
        setIsAlertOpened,
        alertText,
        setAlertText,
        alertTitle,
        setAlertTitle,
        language,
        setLanguage: handleSetLanguage,
        geoPosition,
        setGeoPosition,
        collaboratorProfileFilled,
        setCollaboratorProfileFilled,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
