export const SearchIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_792)">
        <path
          d="M19.7558 18.9857L14.7817 14.0115C16.1372 12.3537 16.8037 10.2383 16.6432 8.10284C16.4828 5.9674 15.5077 3.97531 13.9197 2.53864C12.3316 1.10196 10.2522 0.330615 8.11137 0.384141C5.97058 0.437668 3.93225 1.31197 2.41801 2.82622C0.903771 4.34046 0.0294646 6.37878 -0.0240619 8.51957C-0.0775884 10.6604 0.69376 12.7398 2.13044 14.3279C3.56711 15.9159 5.55919 16.891 7.69464 17.0514C9.83008 17.2119 11.9455 16.5454 13.6033 15.1899L18.5775 20.164C18.7347 20.3158 18.9452 20.3998 19.1637 20.3979C19.3822 20.396 19.5912 20.3084 19.7457 20.1539C19.9002 19.9994 19.9878 19.7904 19.9897 19.5719C19.9916 19.3534 19.9076 19.1429 19.7558 18.9857ZM8.33333 15.4082C7.01479 15.4082 5.72586 15.0172 4.62953 14.2847C3.53321 13.5521 2.67872 12.5109 2.17414 11.2928C1.66955 10.0746 1.53753 8.73414 1.79477 7.44094C2.052 6.14773 2.68694 4.95984 3.61929 4.02749C4.55164 3.09514 5.73953 2.4602 7.03273 2.20297C8.32594 1.94573 9.66638 2.07776 10.8846 2.58234C12.1027 3.08693 13.1439 3.94141 13.8765 5.03774C14.609 6.13406 15 7.423 15 8.74154C14.998 10.509 14.295 12.2036 13.0452 13.4534C11.7954 14.7032 10.1008 15.4062 8.33333 15.4082Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_792">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.408203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
