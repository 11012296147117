import { FC, Suspense, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import ThemeContextWrapper from "../lib/theme/theme-context-wrapper";
import GlobalContextWrapper from "src/context/global-context-wrapper";
import { BrowserRouter } from "react-router-dom";
import ModalProvider from "../components/modal-views/context";
import ModalsContainer from "../components/modal-views/container";
import AppRoutes from "../routes";
import { SpinnerIcon } from "src/components/icons/spinner-icon";

const App: FC = () => {
  const queryClient = new QueryClient();

  return (
    <Suspense
      fallback={
        <div className="bg-whole-gray flex items-center justify-center w-full min-h-screen backdrop-blur-sm">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
        </div>
      }
    >
      <QueryClientProvider client={queryClient}>
        <GlobalContextWrapper>
          <ThemeContextWrapper>
            <ModalProvider>
              <AnimatePresence
                initial={false}
                onExitComplete={() => window.scrollTo(0, 0)}
              >
                <BrowserRouter>
                  <AppRoutes />
                  <ModalsContainer />
                </BrowserRouter>
              </AnimatePresence>
            </ModalProvider>
          </ThemeContextWrapper>
        </GlobalContextWrapper>
      </QueryClientProvider>
    </Suspense>
  );
};

export default App;
