export const DownloadIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_818)">
        <path
          d="M8.23169 15.5099C8.46386 15.7422 8.73953 15.9265 9.04295 16.0522C9.34636 16.178 9.67159 16.2427 10 16.2427C10.3285 16.2427 10.6537 16.178 10.9571 16.0522C11.2605 15.9265 11.5362 15.7422 11.7684 15.5099L14.4442 12.834C14.5877 12.6754 14.6646 12.4676 14.6591 12.2538C14.6536 12.0399 14.5661 11.8364 14.4147 11.6853C14.2633 11.5342 14.0595 11.4471 13.8457 11.442C13.6318 11.437 13.4242 11.5144 13.2659 11.6582L10.8275 14.0974L10.8334 1.24154C10.8334 1.02052 10.7456 0.808561 10.5893 0.652281C10.433 0.496001 10.221 0.408203 10 0.408203C9.77901 0.408203 9.56705 0.496001 9.41077 0.652281C9.25449 0.808561 9.16669 1.02052 9.16669 1.24154L9.15919 14.0815L6.73419 11.6582C6.57783 11.5019 6.36579 11.4142 6.14473 11.4143C5.92367 11.4144 5.7117 11.5023 5.55544 11.6586C5.39919 11.815 5.31145 12.027 5.31152 12.2481C5.3116 12.4691 5.39949 12.6811 5.55586 12.8374L8.23169 15.5099Z"
          fill="currentColor"
        />
        <path
          d="M19.1667 13.7415C18.9457 13.7415 18.7337 13.8293 18.5774 13.9856C18.4211 14.1419 18.3333 14.3538 18.3333 14.5748V17.9082C18.3333 18.1292 18.2455 18.3412 18.0893 18.4974C17.933 18.6537 17.721 18.7415 17.5 18.7415H2.5C2.27899 18.7415 2.06702 18.6537 1.91074 18.4974C1.75446 18.3412 1.66667 18.1292 1.66667 17.9082V14.5748C1.66667 14.3538 1.57887 14.1419 1.42259 13.9856C1.26631 13.8293 1.05435 13.7415 0.833333 13.7415C0.61232 13.7415 0.400358 13.8293 0.244078 13.9856C0.0877974 14.1419 0 14.3538 0 14.5748L0 17.9082C0 18.5712 0.263392 19.2071 0.732233 19.676C1.20107 20.1448 1.83696 20.4082 2.5 20.4082H17.5C18.163 20.4082 18.7989 20.1448 19.2678 19.676C19.7366 19.2071 20 18.5712 20 17.9082V14.5748C20 14.3538 19.9122 14.1419 19.7559 13.9856C19.5996 13.8293 19.3877 13.7415 19.1667 13.7415Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_818">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.408203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
