import Datepicker from "react-tailwindcss-datepicker";
import classNames from "classnames";
import { forwardRef, Fragment, useEffect, useState, useContext } from "react";
import GlobalContext from "src/context/global-context";
import type { DatePickerProps } from "antd";
import { DatePicker, Space } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import it from "antd/es/date-picker/locale/it_IT";
import dayjs from "dayjs";

interface MonthPickerProps {
  label?: string;
  placeholder: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  value?: string;
  onChange?: any;
  required?: boolean;
}
const MonthPicker = forwardRef<HTMLDivElement, MonthPickerProps>(
  (
    {
      label,
      placeholder,
      error,
      className,
      inputClassName = "bg-gray-modern-100 w-full rounded-[8px]",
      onChange,
      value = "",
      required = false,
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState("");
    const { language } = useContext(GlobalContext);

    useEffect(() => {
      setSelectedValue(value);
    }, [value]);

    const handleValueChange: DatePickerProps["onChange"] = (
      date,
      dateString
    ) => {
      onChange(dateString);
    };

    return (
      <div className={className}>
        <label className="block text-[14px]">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <Space direction="vertical" className={inputClassName}>
            <DatePicker
              locale={language.toLowerCase() == "en" ? en : it}
              placeholder={placeholder}
              format={"YYYY-MM"}
              onChange={handleValueChange}
              picker="month"
              value={
                selectedValue.length == 0 ? undefined : dayjs(selectedValue)
              }
              className={classNames(
                "h-[44px] w-full placeholder-gray-modern-400 !bg-gray-modern-10 appearance-none border-none !rounded-[8px] px-4 py-2 text-primary text-[14px] font-gilroy font-medium ring-0 !ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                inputClassName
              )}
            />
          </Space>
        </label>
        {error && (
          <span role="alert" className="block pt-2 text-[12px] text-error">
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

MonthPicker.displayName = "MonthPicker";
export default MonthPicker;
