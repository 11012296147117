import { FC } from "react";
import useAuth from "src/hooks/use-auth";
import { UserType } from "src/api/types";
import CollaboratorEventsPage from "./collaborator";
import AdminEventsPage from "./admin";

const EventsPage: FC = () => {
  const { getCurrentUser } = useAuth();

  const userType = getCurrentUser().user_type ?? "";
  return userType == UserType.COLLAB ? (
    <CollaboratorEventsPage />
  ) : (
    <AdminEventsPage />
  );
};

export default EventsPage;
