import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import {
  calculateDistance,
  capitalizeFirstLetter,
  getEventStatusColor,
  getEventStatusName,
} from "src/lib/utils";
import {
  BidStatus,
  BidStatusForAdmin,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import L from "i18n-react";
import { CollaboratorBidType, CollaboratorType } from "src/lib/data-types";
import { useModalAction } from "src/components/modal-views/context";
import { Event, SearchQueryOptions } from "src/api/types";
import EventDetailsView from "src/components/events/details";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { CheckIcon } from "src/components/icons/check-icon";
import EventBidListView from "src/components/events/bid-list";
import { PlusIcon } from "src/components/icons/plus-icon";
import EventMapView from "src/components/events/map";
import { CrossIcon } from "src/components/icons/cross-icon";
import { ListCheckIcon } from "src/components/icons/list-check-icon";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";

const EventPreviewPage: FC = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [selectedCity, setSelectedCity] = useState<{
    id: number;
    name: string;
    lat: number;
    lng: number;
  } | null>(null);

  const [isListMode, setListMode] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const location = useLocation();
  const event: Event | undefined = location.state;

  const [curEvent, setCurEvent] = useState<Event | undefined>(undefined);

  const { openModal } = useModalAction();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "created_at",
    direction: "desc",
    user_name: "",
    event_date: "",
    payment_date: "",
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollaboratorBidType[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    CollaboratorBidType[]
  >([]);

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const [selectedCollabs, setSelectedCollabs] = useState<number[]>([]);

  useEffect(() => {
    if (!isMounted) return;
    if (!event) return;

    getEvent(event!.id);

    const delayDebounceFn = setTimeout(() => {
      getList({ id: event!.id, query: searchParams });
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.user_name,
    searchParams.event_date,
    searchParams.payment_date,
    searchParams.status,
    searchParams.bid_status,
    isListMode,
  ]);

  useEffect(() => {
    if (isListMode) {
      setFilteredDataSource([...dataSource]);
    } else {
      if (selectedCity) {
        setFilteredDataSource(
          dataSource.filter(
            (item) =>
              calculateDistance(
                selectedCity!.lat,
                selectedCity!.lng,
                item.lat,
                item.lng
              ) < 30
          )
        );
      } else {
        setFilteredDataSource([...dataSource]);
      }
    }
  }, [dataSource, selectedCity, isListMode]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 960 ? true : false);
  }

  const onBack = () => {
    navigate(-1);
  };

  const onChangeViewMode = () => {
    setListMode(!isListMode);
  };

  const { mutate: getEvent, isLoading: isGettingEvent } = useMutation(
    client.events.get,
    {
      onSuccess: (data) => {
        setCurEvent(data.data);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(
    client.events.getCollaboratorBidList,
    {
      onSuccess: (data) => {
        setTotal(data.count);
        setDataSource(
          data.results.map((item, index) => {
            let lat: number = 0;
            let lng: number = 0;

            if (item.user.location) {
              const positions = item.user.location.split(",");
              if (positions.length >= 2) {
                lat = Number(positions[0]);
                lng = Number(positions[1]);
              }
            }

            return {
              key: index,
              id: item.id,
              user_name: `${capitalizeFirstLetter(
                item.user.first_name ?? ""
              )} ${capitalizeFirstLetter(item.user.last_name ?? "")}`,
              user_id: item.user.id,
              lat: lat,
              lng: lng,
              user_email: item.user.email,
              cv: item.cv,
              main_photo: item.main_photo,
              photo2: item.photo2,
              photo3: item.photo3,
              photo4: item.photo4,
              photo5: item.photo5,
              photo6: item.photo6,
              photo7: item.photo7,
              photo8: item.photo8,
              photo9: item.photo9,
              sex: item.info.sex,
              height: item.info.height,
              shoe_size: item.info.shoe_size,
              instagram: item.info.instagram,
              telephone_number: item.info.telephone_number,
              info: item.info,
              status: item.status,
              comp: item.comp ? `${item.comp}` : "-",
              total_comp: item.total_comp,
              reimburse: item.reimburse ? `${item.reimburse}` : "-",
              contract_signed: item.contract_signed,
              contract_pdf: item.contract_pdf,
              notes: item.notes ?? "",
              payment_date: item.payment_date,
              profile: item.profile,
              casting_fields: item.casting_fields ?? "",
              event_date: item.event_date,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          })
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_bids")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onConfirmShortlist = () => {
    navigate(routes.casting);
  };

  const { mutate: shortlist, isLoading: isLoadingShortlist } = useMutation(
    client.events.shortlist,
    {
      onSuccess: (data) => {
        data.code &&
          openModal("WEB_CASTING_CODE", {
            payload: data.code!,
            callback: onConfirmShortlist,
          });
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_shortlist_bids")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onAddCollab = () => {
    //add collabs manually
    event && navigate(routes.selectCollaborators, { state: curEvent ?? event });
  };

  const { mutate: deleteBids, isLoading: isDeletingBids } = useMutation(
    client.events.deleteBids,
    {
      onSuccess: (data) => {
        console.log(data);
        setSelectedCollabs([]);
        setTimeout(() => onRefreshList(), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_bids")));
        }
        setIsAlertOpened(true);
      },
    }
  );
  const onDelete = () => {
    openModal("CONFIRM", { callback: onConfirmDelete });
  };

  const onConfirmDelete = () => {
    deleteBids({ event_id: event!.id, bid_ids: selectedCollabs.join(",") });
  };

  const onReorder = () => {
    openModal("REORDER_COLLABS", {
      payload: { event: curEvent ?? event },
      refreshCallback: onRefreshList,
    });
  };

  const onConfirm = () => {
    /*
    let bidIDs: number[] = [];
    dataSource.map((item, index) => {
      if (selectedCollabs.includes(index)) {
        bidIDs = [...bidIDs, item.id];
      }
    });
    */

    shortlist({ event_id: event!.id, bid_ids: selectedCollabs.join(",") });
  };

  const onRefreshList = () => {
    getEvent(event!.id);
    getList({ id: event!.id, query: searchParams });
  };

  const onEditDates = () => {
    openModal("EVENT_EDIT_DATES", {
      payload: { event: curEvent ?? event },
      refreshCallback: onRefreshList,
    });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    const tempKeys = newSelectedRowKeys.map((item) => Number(item));
    setSelectedCollabs(tempKeys.map((item) => filteredDataSource[item].id));
    //setSelectedCollabs(newSelectedRowKeys.map((item) => Number(item)));
  };

  const getSelectedRowKeys = () => {
    const rowKeys = selectedCollabs.map((item) =>
      filteredDataSource.findIndex(
        (eachDatasource) => eachDatasource.id == item
      )
    );
    return rowKeys;
  };

  const rowSelection = {
    selectedRowKeys: getSelectedRowKeys(),
    onChange: onSelectChange,
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px]">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>
        <div className="flex flex-row items-center justify-start gap-4 mt-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("CommonForm.event_preview")}
          </div>
          <p
            className="font-gilroy text-white text-center text-[14px] rounded-[8px] px-2"
            style={{
              backgroundColor: getEventStatusColor(
                curEvent ? curEvent!.status : event!.status
              ),
            }}
          >
            {`${L.translate("CommonForm.status")}: ${L.translate(
              getEventStatusName(curEvent ? curEvent!.status : event!.status)
            )}`}
          </p>
        </div>
        {event && (
          <EventDetailsView
            event={curEvent ?? event}
            isEditDates={true}
            onEdit={onEditDates}
          />
        )}
      </div>
      {event && (
        <div className="bg-white p-4 rounded-[8px] mt-4 mb-4">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-4 lg:gap-0">
            <div className="flex flex-row items-center justify-between lg:justify-start gap-2 sm:gap-4 w-full lg:w-auto">
              <div className="text-primary text-[24px] font-gilroy-semibold">
                {L.translate("Sidebar.collaborators")}
              </div>
              {
                /*event.status != 3 &&*/ <Button
                  variant="icon"
                  className="bg-active p-2 rounded-[8px] hover:bg-main text-white"
                  onClick={onAddCollab}
                >
                  <PlusIcon className="text-white w-[20px] h-[20px]" />
                </Button>
              }
              {/*
                <Button
                  variant="icon"
                  className="bg-active p-2 rounded-[8px] hover:bg-main"
                  onClick={onChangeViewMode}
                >
                  {isListMode ? (
                    <MarkerIcon className="text-white w-[20px] h-[20px]" />
                  ) : (
                    <MenuBurgerIcon className="text-white w-[20px] h-[20px]" />
                  )}
                </Button>
              */}
            </div>
            <DropdownWithoutLabel
              placeholder={String(
                L.translate("CommonForm.collaborator_status")
              )}
              className="w-full lg:w-[240px] z-40"
              data={[
                {
                  id: -1,
                  name: String(L.translate("CommonForm.collaborator_status")),
                },
                ...BidStatusForAdmin.map((item) => ({
                  ...item,
                  name: String(L.translate(item.name)),
                })),
              ]}
              onChange={(e: number) =>
                setSearchParams({ ...searchParams, bid_status: e })
              }
            />
            {event.status >= 0 && (
              <div className="flex flex-row gap-4 justify-between w-full lg:w-auto">
                <Button
                  variant="solid"
                  className={`${
                    dataSource.length > 0 ? "bg-purple" : "bg-[#BCBEAD]"
                  } rounded-[8px] text-[12px] font-gilroy leading-[24px] !h-[36px] text-white hover:bg-red-400`}
                  onClick={onReorder}
                  disabled={dataSource.length == 0}
                >
                  <ListCheckIcon className="w-[16px] h-[16px] text-white" />
                  <span className="text-[14px] font-gilroy">
                    {L.translate("Base.reorder")}
                  </span>
                </Button>
                <Button
                  variant="solid"
                  className={`${
                    selectedCollabs.length > 0 ? "bg-error" : "bg-[#BCBEAD]"
                  } rounded-[8px] text-[12px] font-gilroy leading-[24px] !h-[36px] text-white hover:bg-error-100`}
                  onClick={onDelete}
                  disabled={selectedCollabs.length == 0 || isDeletingBids}
                  isLoading={isDeletingBids}
                >
                  <CrossIcon className="w-[16px] h-[16px] text-white" />
                  <span className="text-[14px] font-gilroy">
                    {L.translate("Base.delete")}
                  </span>
                </Button>
                <Button
                  variant="solid"
                  className={`${
                    selectedCollabs.length > 0 ? "bg-active" : "bg-[#BCBEAD]"
                  } rounded-[8px] text-[12px] font-gilroy leading-[24px] !h-[36px] text-white hover:bg-main`}
                  onClick={onConfirm}
                  disabled={selectedCollabs.length == 0 || isLoadingShortlist}
                  isLoading={isLoadingShortlist}
                >
                  <CheckIcon className="w-[16px] h-[16px] text-white" />
                  <span className="text-[14px] font-gilroy">
                    {L.translate("Base.cast")}
                  </span>
                </Button>
              </div>
            )}
          </div>
          <EventBidListView
            isLoading={isLoading}
            event={event}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            total={total}
            dataSource={filteredDataSource}
            rowSelection={rowSelection}
            refreshList={() => getList({ id: event!.id, query: searchParams })}
          />
        </div>
      )}
      {event && <EventMapView event={curEvent ?? event} />}
    </div>
  );
};

export default EventPreviewPage;
