export const API_ENDPOINTS = {
  USERS_LOGIN: "/login",
  USERS_LOGOUT: "/logout",
  COLLAB_REGISTER: "/register/collab",
  BASIC_ADMIN_REGISTER: "/register/basic-admin",
  EDIT_USER: "/user/edit",
  DELETE_USER: "/user/delete",
  APPROVE_USER: "/user/approve",
  CHANGE_PASSWORD: "/user/change-password",
  FORGOT_PASSWORD: "/user/forgot-password",
  RESET_PASSWORD: "/user/reset-password",
  UPDATE_PROFILE: "/user/update-profile",
  UPDATE_AVATAR: "/user/update-avatar",
  ADD_PHOTO: "/user/add-photo",
  DELETE_PHOTO: "/user/delete-photo",
  USERS_ME: "/user/me",
  USER_LOCATION_UPDATE: "/user/location-update",
  COLLAB_USERS: "/user/collab-list",
  COLLAB_USERS_DOWNLOAD: "user/collab-list/download",
  COLLAB_LEAVE_REVIEW: "/user/collab/review",
  BASIC_ADMIN_USERS: "/user/basic-admin-list",
  CLIENTS: "/client/list",
  CLIENT_REGISTER: "/client/register",
  EDIT_CLIENT: "/client/edit",
  DELETE_CLIENT: "/client/delete",
  EVENTS: "/event/list",
  EVENT_BY_ID: "/event",
  EVENT_INFO_EXPORT_BY_ID: "event/export-excel",
  EVENT_PRE_REGISTER: "/event/pre-register",
  EVENT_REGISTER: "/event/register",
  EVENT_DELETE: "/event/delete",
  EVENT_ARCHIVE: "/event/archive",
  EVENT_UPDATE_DATES: "/event/update-dates",
  EVENT_LIST_BY_DATE: "/event/list-by-date",
  EVENT_LIST_BY_WEEK: "/event/list-by-week",
  COLLABORATOR_EVENTS: "/event/collaborator",
  COLLABORATOR_BIDS_PER_EVENT: "/event/bid-list",
  AVAILABLE_COLLABORATORS_IN_EVENT: "/event/available-collaborator-list",
  COLLABORATORS_FOR_REPLACE: "event/collaborators/replace",
  ADD_COLLABORATORS_IN_EVENT: "/event/add-collaborators",
  COLLABORATORS_IN_EVENT: "event/collaborators",
  COLLABORATORS_REORDER_IN_EVENT: "event/collaborators/reorder",
  BID_WITH_PROFILE: "/collaborator/bid-with-profile",
  COLLABORATOR_ADD_PROFILE: "/collaborator/add-profile",
  COLLABORATOR_UPDATE_PROFILE: "/collaborator/update-profile",
  COLLABORATOR_PROFILE: "/collaborator/profile",
  COLLABORATOR_UPDATE_STATUS: "/collaborator/bid-status",
  GET_RECEIVED_CONTRACTS_AMOUNT: "/event/get-received-contracts",
  SHORTLIST_BIDS: "/event/bid-list/shortlist",
  DELETE_BIDS: "/event/bid-list/delete",
  UPDATE_COMP_VALUES: "/event/collaborator-comp-update",
  SEND_CONTRACT: "/event/contract-send",
  SIGN_CONTRACT: "/collaborator/contract-signed",
  COLLABORATOR_BIDS: "collaborator/bid-list",
  COLLABORATOR_ALL_BIDS: "/collaborator/bid-list",
  WEBCASTING_LIST: "/web-casting/list",
  WEBCASTING_CONFIRM: "/web-casting/confirm",
  WEBCASTING_RESET: "/web-casting/reset",
  WEBCASTING_ARCHIVE: "/web-casting/archive",
  WEBCASTING_DELETE: "/web-casting/delete",
  WEBCASTING_PHOTOS: "/web-casting/photos",
  WEBCASTING_PHOTOS_UPDATE: "/web-casting/update-photos",
  INVOICES: "/invoice/list",
  INVOICE_CREATE: "/invoice/create",
  INVOICE_STATUS: "/invoice/status",
  INVOICE_ARCHIVE: "/invoice/archive",
  INVOICE_DELETE: "/invoice/delete",
  INVOICE_MARK_AS_PAID: "/invoice/mark-as-paid",
  PAYMENTS: "/payment/list",
  PAYMENT_STATUS: "/payment/status",
  PAYMENT_ARCHIVE: "/payment/archive",
  PAYMENT_DELETE: "/payment/delete",
  PAYMENT_MARK_AS_PAID: "/payment/mark-as-paid",
  REPORT_STATUS: "/report/status",
  REPORT_EVENTS: "/report/event-status",
  REPORT_COLLABS: "/report/collab-status",
  REPORT_CLIENTS_COST: "/report/client-status",
  SEND_JOB_ADS: "/ads/send",
  JOB_ADS_LIST: "/ads/list",
};
