import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import L from "i18n-react";

type InputWithoutLabelProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  className?: string;
  value?: string;
  onChange?: any;
  inputClassName?: string;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
};

const AddressInput = forwardRef<HTMLInputElement, InputWithoutLabelProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      showInfo = false,
      info,
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const [strAddressNumber, setAddressNumber] = useState("");
    const [strCity, setCity] = useState("");
    const [strProvince, setProvince] = useState("");
    const [strPostalCode, setPostalCode] = useState("");

    useEffect(() => {
      if (value) {
        const items = value.split("$$$");
        if (items.length >= 4) {
          setAddressNumber(items[0]);
          setCity(items[1]);
          setProvince(items[2]);
          setPostalCode(items[3]);
        }
      }
    }, [value]);

    useEffect(() => {
      if (!onChange) return;

      const delayDebounceFn = setTimeout(() => {
        if (
          strAddressNumber.trim().length == 0 ||
          strCity.trim().length == 0 ||
          strProvince.trim().length == 0 ||
          strPostalCode.trim().length == 0
        ) {
          onChange("");
        } else {
          onChange(
            `${strAddressNumber}$$$${strCity}$$$${strProvince}$$$${strPostalCode}`
          );
        }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }, [strAddressNumber, strCity, strPostalCode, strProvince]);

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <div className="flex flex-col w-full items-center justify-center gap-4">
            <div className="flex flex-col w-full items-start gap-2">
              <input
                type={type}
                value={strAddressNumber}
                onChange={(e) => setAddressNumber(e.target.value)}
                {...props}
                className={classNames(
                  "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                  inputClassName
                )}
              />
              <label className="text-[12px] text-secondary font-gilroy font-medium">
                {L.translate("CollaboratorProfile.address_number")}
              </label>
            </div>
            <div className="flex flex-row items-center justify-center gap-4 w-full">
              <div className="flex flex-col w-full items-start gap-2">
                <input
                  type={type}
                  value={strCity}
                  onChange={(e) => setCity(e.target.value)}
                  {...props}
                  className={classNames(
                    "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                    inputClassName
                  )}
                />
                <label className="text-[12px] text-secondary font-gilroy font-medium">
                  {L.translate("CollaboratorProfile.city")}
                </label>
              </div>
              <div className="flex flex-col w-full items-start gap-2">
                <input
                  type={type}
                  value={strProvince}
                  onChange={(e) => setProvince(e.target.value)}
                  {...props}
                  className={classNames(
                    "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                    inputClassName
                  )}
                />
                <label className="text-[12px] text-secondary font-gilroy font-medium">
                  {L.translate("CollaboratorProfile.province")}
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full items-start gap-2">
              <input
                type={type}
                value={strPostalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                {...props}
                className={classNames(
                  "h-[44px] placeholder-gray-modern-400 border-none w-[50%] sm:w-[30%] appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                  inputClassName
                )}
              />
              <label className="text-[12px] text-secondary font-gilroy font-medium">
                {L.translate("CollaboratorProfile.postal_code")}
              </label>
            </div>
          </div>
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

AddressInput.displayName = "AddressInput";
export default AddressInput;
