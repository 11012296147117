import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import Button from "src/components/ui/button";
import { PlusSmallIcon } from "src/components/icons/plus-small-icon";
import { LIST_PAGE_SIZE } from "src/lib/constants";
import { Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { capitalizeFirstLetter } from "src/lib/utils";
import { FileEditIcon } from "src/components/icons/file-edit-icon";
import { CrossIcon } from "src/components/icons/cross-icon";
import { SearchQueryOptions, User } from "src/api/types";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { SortIcon } from "src/components/icons/sort-icon";
import { AdminDataType } from "src/lib/data-types";
import L from "i18n-react";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";

const AdminsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const [dataSource, setDataSource] = useState<AdminDataType[]>([]);
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "first_name",
    direction: "desc",
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!isMounted) return;

    getList(searchParams);
  }, [isMounted, searchParams.page, searchParams.sort, searchParams.direction]);

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const { mutate: getList, isLoading } = useMutation(
    client.users.basicAdminUsersList,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.count);
        setDataSource(
          data.results.map((item, index) => ({
            key: index,
            id: item.id,
            first_name: item.first_name ?? "",
            last_name: item.last_name ?? "",
            email: item.email,
            permissions: item.permissions ?? "",
          }))
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_basic_admin")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: deleteAdmin, isLoading: isDeletingAdmin } = useMutation(
    client.users.delete,
    {
      onSuccess: (data) => {
        setTimeout(() => onRefresh(), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_admin")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onRefresh = () => {
    getList(searchParams);
  };

  const onAdd = () => {
    openModal("ADD_BASIC_ADMIN", { refreshCallback: onRefresh });
  };

  const onClickedAdmin = (user: AdminDataType) => {
    openModal("EDIT_BASIC_ADMIN", {
      payload: user,
      refreshCallback: onRefresh,
    });
  };

  const onDelete = (userId: number) => {
    openModal("CONFIRM", { payload: userId, callback: onConfirmDelete });
  };

  const onConfirmDelete = (userId: number) => {
    //delete process
    deleteAdmin(userId);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const columns: ColumnsType<AdminDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.email")}
        </p>
      ),
      dataIndex: "email",
      className: "w-auto",
      key: "email",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.first_name")),
          "first_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "first_name",
      className: "w-auto",
      key: "first_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.last_name")),
          "last_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "last_name",
      className: "w-auto",
      key: "last_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (_, record: { key: React.Key; id: number }) => {
        return (
          <div className="flex flex-row items-center justify-between">
            <Button
              variant="icon"
              className="bg-error hover:!bg-warning !text-white w-8 h-8 text-[14px] font-gilroy rounded-[8px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(record.id);
              }}
            >
              <CrossIcon className="w-[12px] h-[12px]" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.delete")}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  const mobileColumns: ColumnsType<AdminDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.email")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.email}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.name")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {`${capitalizeFirstLetter(
                  selData.first_name
                )} ${capitalizeFirstLetter(selData.last_name)}`}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <Button
                variant="text"
                className="bg-error uppercase text-[14px] font-gilroy px-8 !text-white h-[40px] rounded-[8px] hover:bg-warning"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                {L.translate("Base.delete")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-row items-center justify-start gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("Sidebar.admins")}
          </div>
          <Button
            variant="icon"
            className="bg-active p-1 rounded-[8px] hover:bg-main"
            onClick={onAdd}
          >
            <PlusSmallIcon className="text-white w-[20px] h-[20px]" />
          </Button>
        </div>
        <div className={`relative w-full ${!isMobile && "mt-4"}`}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p className="font-gilroy-semibold text-secondary text-[14px]">
                      {L.translate("Base.no_data")}
                    </p>
                  }
                />
              ),
            }}
            showSorterTooltip={false}
            loading={isLoading || isDeletingAdmin}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onClickedAdmin(record),
              };
            }}
            pagination={{
              total,
              current: searchParams.page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminsPage;
