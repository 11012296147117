import { FC, useContext, useEffect, useState } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import DropdownWithSearchWithoutLabel from "src/components/ui/dropdown-search-without-label";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import Textarea from "src/components/ui/textarea";
import { useMutation } from "react-query";
import client from "../../api";
import { Client } from "src/api/types";
import GlobalContext from "src/context/global-context";
import L from "i18n-react";
import { Spin } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputWithTwoDecimals from "src/components/ui/input-decimals";
import { AccountNames, MAX_CHARACTERS_LENGTH } from "src/lib/constants";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import { capitalizeFirstLetter } from "src/lib/utils";

const CreateEventPage: FC = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [eventDescr, setEventDescr] = useState("");
  const [invoiceDescr, setInvoiceDescr] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);
  const [clients, setClients] = useState<Client[]>([]);
  const [admins, setAdmins] = useState<{ id: number; name: string }[]>([]);

  const [contract, setContract] = useState("");
  const [invoice_no, setInvoiceNo] = useState("");
  const [quote_no, setQuoteNo] = useState("");

  const schema = yup
    .object({
      name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      client_id: yup
        .number()
        .positive()
        .required(String(L.translate("ValidationErrors.field_required"))),
      account: yup
        .number()
        .positive()
        .required(String(L.translate("ValidationErrors.field_required"))),
      description: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      invoice_descr: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { mutate: getClients, isLoading: isLoadingClients } = useMutation(
    client.clients.all,
    {
      onSuccess: (data) => {
        setClients(data.results);
      },
    }
  );

  const { mutate: getAdmins, isLoading: isLoadingAdmins } = useMutation(
    client.users.basicAdminUsersAll,
    {
      onSuccess: (data) => {
        setAdmins(
          data.results.map((item) => ({
            id: item.id + 10,
            name: `${capitalizeFirstLetter(
              item.first_name ?? ""
            )} ${capitalizeFirstLetter(item.last_name ?? "")}`,
          }))
        );
      },
    }
  );

  const { mutate: createNew, isLoading } = useMutation(
    client.events.preRegister,
    {
      onSuccess: (data) => {
        navigate(routes.planJob, { state: data.data });
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_create_event")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;

    getAdmins();
    getClients();
  }, [isMounted]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onBack = () => {
    navigate(-1);
  };

  const onCreateEvent = (data: FormData) => {
    createNew({
      ...data,
      invoice_no,
      quote_no,
      contract: contract.length == 0 ? undefined : Number(contract),
    });
  };

  return (
    <div className="relative w-full h-full backdrop-blur">
      {(isLoadingClients || isLoadingAdmins) && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}

      <div className="bg-white p-4 rounded-[8px] mb-4">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>
        <div className="text-primary text-[24px] font-gilroy-semibold mt-4">
          {L.translate("Sidebar.new_event")}
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col sm:flex-row items-start justify-between gap-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.event_name"))}
            className="w-full"
            {...register("name")}
            error={errors.name && (errors.name.message as string)}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col sm:flex-row items-start justify-between gap-4">
          <Controller
            name="client_id"
            control={control}
            render={({ field }) => (
              <DropdownWithSearchWithoutLabel
                placeholder={String(L.translate("CommonForm.client_name"))}
                searchPlaceholder={String(L.translate("Base.type_client_name"))}
                className="w-full"
                data={clients}
                error={errors.client_id && (errors.client_id.message as string)}
                {...field}
              />
            )}
          />
          <Controller
            name="account"
            control={control}
            render={({ field }) => (
              <DropdownWithoutLabel
                placeholder={String(L.translate("CommonForm.account_name"))}
                className="w-full"
                data={[...AccountNames, ...admins]}
                error={errors.account && (errors.account.message as string)}
                {...field}
              />
            )}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col sm:flex-row items-center justify-between gap-4">
          <div className="relative w-full">
            <Textarea
              placeholder={String(L.translate("CommonForm.event_description"))}
              className="w-full mb-4 font-gilroy"
              value={eventDescr}
              onChange={(e) => {
                if (e.target.value.length <= MAX_CHARACTERS_LENGTH) {
                  setEventDescr(e.target.value);
                  setValue("description", e.target.value, {
                    shouldValidate: true,
                  });
                }
              }}
            />
            <span className="absolute bottom-1 right-1 block cursor-pointer text-[12px] font-gilroy font-normal text-secondary">
              {`${
                eventDescr.length === 0
                  ? MAX_CHARACTERS_LENGTH
                  : MAX_CHARACTERS_LENGTH - eventDescr.length
              } ${L.translate("CommonForm.characters")}`}
            </span>
          </div>
        </div>
        <div className="text-primary text-[24px] font-gilroy-semibold mt-8">
          {`${L.translate("CommonForm.contract")} (€)`}
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col sm:flex-row items-start justify-between gap-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.invoice_no"))}
            className="w-full"
            value={invoice_no}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.quote_no"))}
            className="w-full"
            value={quote_no}
            onChange={(e) => setQuoteNo(e.target.value)}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col items-center justify-between gap-4">
          <InputWithTwoDecimals
            type="number"
            placeholder={String(L.translate("CommonForm.total_from_customer"))}
            className="w-full"
            value={contract}
            onChange={(e: string) => setContract(e)}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[80%] lg:w-[70%] flex-col sm:flex-row items-center justify-between gap-4">
          <div className="relative w-full">
            <Textarea
              placeholder={String(L.translate("CommonForm.invoice_descr"))}
              className="w-full mb-4 font-gilroy"
              value={invoiceDescr}
              onChange={(e) => {
                if (e.target.value.length <= MAX_CHARACTERS_LENGTH) {
                  setInvoiceDescr(e.target.value);
                  setValue("invoice_descr", e.target.value, {
                    shouldValidate: true,
                  });
                }
              }}
            />
            <span className="absolute bottom-1 right-1 block cursor-pointer text-[12px] font-gilroy font-normal text-secondary">
              {`${
                invoiceDescr.length === 0
                  ? MAX_CHARACTERS_LENGTH
                  : MAX_CHARACTERS_LENGTH - invoiceDescr.length
              } ${L.translate("CommonForm.characters")}`}
            </span>
          </div>
        </div>
        <Button
          variant="validate"
          type="submit"
          className="mt-8 rounded-[10px] uppercase font-gilroy text-[14px] font-bold leading-[24px] h-[44px]"
          onClick={handleSubmit(onCreateEvent)}
          isLoading={isLoading}
          disabled={!isValid}
        >
          {L.translate("CommonForm.create_event")}
        </Button>
      </div>
    </div>
  );
};

export default CreateEventPage;
