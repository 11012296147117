import { useState, forwardRef } from "react";
import cn from "classnames";
import { EyeIcon } from "../icons/eye-icon";
import { EyeCloseIcon } from "../icons/eye-close-icon";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
};

const Password = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      ...props
    },
    ref
  ) => {
    let [showPassword, setShowPassword] = useState(false);
    return (
      <div className={className}>
        <label className="block text-[14px] font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {label}
            </span>
          )}

          <span className="relative block">
            <input
              type={showPassword ? "text" : "password"}
              ref={ref}
              {...props}
              className={cn(
                "h-[50px] sm:h-[64px] w-full appearance-none text-[16px] font-gilroy font-medium rounded-[12px] border border-border bg-transparent pl-4 pr-10 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:pl-5",
                error
                  ? "focus:border-error !border-error focus:ring-error"
                  : "focus:border-active focus:ring-active dark:focus:border-active dark:focus:ring-active",
                inputClassName
              )}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9"
            >
              {showPassword ? (
                <EyeIcon className="h-auto w-5" />
              ) : (
                <EyeCloseIcon className="h-auto w-5" />
              )}
            </span>
          </span>
        </label>

        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

Password.displayName = "Password";
export default Password;
