import { FC, useEffect, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../layouts/_layout";
import routes from "../config/routes";
import PrivateRoute from "../lib/private-route";
import WelcomePage from "../pages/welcome";
import SignInPage from "../pages/authentication/sign-in";
import SignUpPage from "../pages/authentication/sign-up";
import EventsPage from "src/pages/events";
import PlanJobPage from "src/pages/planJob";
import DatabasePage from "src/pages/database";
import CastingPage from "src/pages/casting";
import CalendarPage from "src/pages/calendar";
import PaymentsPage from "src/pages/payments";
import InvoicesPage from "src/pages/invoices";
import ReportsPage from "src/pages/reports";
import SettingsPage from "src/pages/settings";
import NotFound from "src/pages/404";
import ForgotPasswordPage from "src/pages/authentication/forgot-password";
import CreateEventPage from "src/pages/events/create-new";
import EventPreviewPage from "src/pages/events/preview";
import WebCastingPage from "src/pages/casting/web";
import SelectCollaboratorsPage from "src/pages/events/select-collaborators";
import GlobalContext from "src/context/global-context";
import AdminsPage from "src/pages/admins";
import ClientsPage from "src/pages/clients";
import CollaboratorsPage from "src/pages/collaborators";
import PrivacyPage from "src/pages/privacy";
import ProfilePage from "src/pages/profile";
import CreateInvoicePage from "src/pages/invoices/create-new";
import ContractPDFDownload from "src/components/contract/generate-pdf";
import CreateCastingPage from "src/pages/casting/create-new";
import { useMutation } from "react-query";
import client from "../api";
import PaymentPDFDownload from "src/components/payment/generate-note";
import ResetPasswordPage from "src/pages/authentication/reset-password";
import JobAdsPage from "src/pages/ads";

const AppRoutes: FC = () => {
  const { setGeoPosition } = useContext(GlobalContext);
  /*
  const { mutate: updateLocation, isLoading } = useMutation(
    client.users.updateLocation,
    {
      onSuccess: (data) => {
        console.log("LOCATION IS UPDATED");
      },
    }
  );

  //---get geo position---
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos: any) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    setGeoPosition({ lat: crd.latitude, lng: crd.longitude });

    updateLocation({
      lat: Number(crd.latitude) ?? 0,
      lng: Number(crd.longitude) ?? 0,
    });
  }

  function errors(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);
  //---END---
  */

  return (
    <Routes>
      <Route
        index
        element={
          <Layout>
            <WelcomePage />
          </Layout>
        }
      />
      <Route
        path="*"
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
      <Route
        path={routes.login}
        element={
          <Layout>
            <SignInPage />
          </Layout>
        }
      />
      <Route
        path={routes.register}
        element={
          <Layout>
            <SignUpPage />
          </Layout>
        }
      />
      <Route
        path={routes.forgot}
        element={
          <Layout>
            <ForgotPasswordPage />
          </Layout>
        }
      />
      <Route
        path={routes.reset}
        element={
          <Layout>
            <ResetPasswordPage />
          </Layout>
        }
      />
      <Route
        path={routes.events}
        element={
          <PrivateRoute>
            <Layout>
              <EventsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.createEvent}
        element={
          <PrivateRoute>
            <Layout>
              <CreateEventPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.eventPreview}
        element={
          <PrivateRoute>
            <Layout>
              <EventPreviewPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.selectCollaborators}
        element={
          <PrivateRoute>
            <Layout>
              <SelectCollaboratorsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.planJob}
        element={
          <PrivateRoute>
            <Layout>
              <PlanJobPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.jobAds}
        element={
          <PrivateRoute>
            <Layout>
              <JobAdsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.database}
        element={
          <PrivateRoute>
            <Layout>
              <DatabasePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.clients}
        element={
          <PrivateRoute>
            <Layout>
              <ClientsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.casting}
        element={
          <PrivateRoute>
            <Layout>
              <CastingPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.createCasting}
        element={
          <PrivateRoute>
            <Layout>
              <CreateCastingPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route path={routes.webCasting} element={<WebCastingPage />} />
      <Route path={routes.privacy} element={<PrivacyPage />} />
      <Route
        path={routes.calendar}
        element={
          <PrivateRoute>
            <Layout>
              <CalendarPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.payments}
        element={
          <PrivateRoute>
            <Layout>
              <PaymentsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.invoices}
        element={
          <PrivateRoute>
            <Layout>
              <InvoicesPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.createInvoice}
        element={
          <PrivateRoute>
            <Layout>
              <CreateInvoicePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.reports}
        element={
          <PrivateRoute>
            <Layout>
              <ReportsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.settings}
        element={
          <PrivateRoute>
            <Layout>
              <SettingsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.profile}
        element={
          <PrivateRoute>
            <Layout>
              <ProfilePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.admins}
        element={
          <PrivateRoute>
            <Layout>
              <AdminsPage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.collaborators}
        element={
          <PrivateRoute>
            <Layout>
              <CollaboratorsPage />
            </Layout>
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
