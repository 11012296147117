import { FC, useRef, useState, useEffect, useContext } from "react";
import { Event, UserType } from "src/api/types";
import {
  capitalizeFirstLetter,
  getContractStatusTextColor,
  getEventDurationString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { ConfigValue } from "src/config";
import { UserIcon } from "../icons/user-icon";
import useAuth from "src/hooks/use-auth";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

const EventMapView = ({ event }: { event: Event }) => {
  const { getCurrentUser } = useAuth();
  const { language } = useContext(GlobalContext);

  const userType = getCurrentUser().user_type ?? "";

  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  useEffect(() => {
    if (event.coordinate) {
      const positions = event.coordinate!.split(",");
      positions.length >= 2
        ? setSelectedLocation({
            lat: Number(positions[0]),
            lng: Number(positions[1]),
          })
        : setSelectedLocation(null);
    } else {
      setSelectedLocation(null);
    }
  }, [event.location]);

  return (
    <div className="mt-4 flex w-full flex-col items-center justify-center p-4 h-[360px] bg-white rounded-[8px] mb-4">
      {selectedLocation && (
        <APIProvider apiKey={ConfigValue.GOOGLE_MAPS_API}>
          <Map
            mapId={ConfigValue.GOOGLE_MAP_ID}
            defaultCenter={{
              lat: selectedLocation?.lat,
              lng: selectedLocation?.lng,
            }}
            defaultZoom={13}
            gestureHandling={"greedy"}
            style={{
              width: "100%",
              backgroundColor: "#f2f4f7",
              height: "100%",
              borderRadius: 8,
              overflow: "clip",
            }}
            disableDefaultUI
          >
            <AdvancedMarker
              position={{
                lat: selectedLocation.lat,
                lng: selectedLocation.lng,
              }}
            >
              <div className="flex items-center justify-center bg-active w-[64px] h-[64px] rounded-full">
                <img
                  src={isMobile ? "/logo192.png" : "/logo512.png"}
                  className="w-[64px] h-[64px] object-cover rounded-full"
                />
              </div>
            </AdvancedMarker>
          </Map>
        </APIProvider>
      )}
    </div>
  );
};

export default EventMapView;
